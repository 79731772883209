<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div
          v-if="!isFetching"
          class="main-content-inner">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary m-b-4x">
            {{ $t('views.MyAccount.Account Preferences') }}
          </h2>
          <div class="columns is-gapless m-b-0 section-header">
            <div class="column">
              <div>
                {{ $t('views.MyAccount.Global preferences') }}
              </div>
            </div>
            <div class="column has-text-right">
              <a
                class="has-text-white"
                @click.prevent="globalPreferencesModalActive=true">
                <IconBase
                  width="13"
                  height="13"
                  icon-name="edit">
                  <IconEdit />
                </IconBase>
                {{ $t('views.MyAccount.Edit') }}
              </a>
            </div>
          </div>
          <!-- <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">{{ $t('views.MyAccount.Global preferences') }}</div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click.prevent="globalPreferencesModalActive=true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit/>
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div> -->
          <div class="columns is-gapless columns-table m-b-3x">
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.language.label') }}
                </div>
                <div>{{ $t(`languageOptions.${globalPreferencesForm.language}`) }}</div>
              </div>
            </div>
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.currency.label') }}
                </div>
                <div>{{ globalPreferencesForm.currency }}</div>
              </div>
            </div>
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.timezone.label') }}
                </div>
                <div>{{ globalPreferencesForm.timezone }}</div>
              </div>
            </div>
          </div>
          <div class="columns is-gapless m-b-0 section-header">
            <div class="column">
              <div>
                {{ $t('views.MyAccount.Guest preferences') }}
              </div>
            </div>
            <div class="column has-text-right">
              <a
                class="has-text-white"
                @click.prevent="guestPreferencesModalActive=true">
                <IconBase
                  width="13"
                  height="13"
                  icon-name="edit">
                  <IconEdit />
                </IconBase>
                {{ $t('views.MyAccount.Edit') }}
              </a>
            </div>
          </div>
          <!-- <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">{{ $t('views.MyAccount.Guest preferences') }}</div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click.prevent="guestPreferencesModalActive=true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit/>
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div> -->
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column is-12">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.nationality.label') }}
                </div>
                <div>{{ $t(`countries.${getCountry(guestPreferencesForm.nationality, 'a3')}`) }}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- Global preferences modal -->
    <PpModal v-model="globalPreferencesModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="globalPreferencesForm"
            :model="globalPreferencesForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit global preferences') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.language.label')"
              class="column is-12"
              prop="language">
              <InputLanguage
                v-model.trim="globalPreferencesForm.language"
                :placeholder="$t('views.customer.form.country.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.currency.label')"
              class="column is-12"
              prop="currency">
              <InputCurrency
                v-model.trim="globalPreferencesForm.currency"
                :placeholder="$t('views.customer.form.country.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.timezone.label')"
              class="column is-12"
              prop="timezone">
              <InputTimeZone
                v-model.trim="globalPreferencesForm.timezone"
                :placeholder="$t('views.customer.form.country.placeholder')"
                style="width: 100%;" />
            </el-form-item>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSave">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <!-- Guest preferences modal -->
    <PpModal v-model="guestPreferencesModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="guestPreferencesForm"
            :model="guestPreferencesForm"
            :rules="guestPreferencesRules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit guest preferences') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.nationality.label')"
              class="column is-12"
              prop="nationality">
              <InputNationality
                v-model.trim="guestPreferencesForm.nationalityObj"
                :placeholder="$t('views.customer.form.country.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancelGuestPreferences">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSaveGuestPreferences">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'

import IconEdit from '@/components/icons/IconEdit'
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'
import InputNationality from '@/components/InputNationality'
import InputLanguage from '@/components/InputLanguage'
import InputCurrency from '@/components/InputCurrency'
import InputTimeZone from '@/components/InputTimeZone'

export default {
  name: 'AccountPreferences',
  components: {
    InputTimeZone,
    InputCurrency,
    InputLanguage,
    InputNationality,
    MyAccountSidebar,
    IconEdit
  },
  data () {
    return {
      isFetching: false,
      globalPreferencesModalActive: false,
      guestPreferencesModalActive: false,
      globalPreferencesForm: {
        language: undefined,
        currency: undefined,
        timezone: undefined
      },
      rules: {
        language: [
          {
            required: true,
            message: this.$t('views.customer.form.company-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t('views.customer.form.country.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        timeZone: [
          {
            required: true,
            message: this.$t('views.customer.form.company-address.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      guestPreferencesForm: {
        nationality: undefined
      },
      guestPreferencesRules: {
        nationality: [
          {
            required: true,
            message: this.$t('views.customer.form.company-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },
  created () {
    this.isFetching = true
    this.$store.commit('SHOW_FULLSCREEN_LOADER')
    this.$store.dispatch('app/getAppInfo').then(
      () => {
        this.initForm(this.$store.state.app)
        this.isFetching = false
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      }
    )
  },
  methods: {
    initForm (form) {
      this.globalPreferencesForm.language = form.defaultLocale
      this.globalPreferencesForm.currency = form.defaultDisplayCurrency
      this.globalPreferencesForm.timezone = form.timezone
      this.guestPreferencesForm.nationality = form.defaultSourceMarket
      this.guestPreferencesForm = {
        ...this.guestPreferencesForm,
        nationalityObj: getCountryByAlpha2(form.defaultSourceMarket)
      }
    },
    getCountry (a2, key) {
      return getCountryByAlpha2(a2)[key]
    },
    handleSave () {
      this.$refs.globalPreferencesForm.validate((valid) => {
        if (valid) {
          const payload = {
            defaultLocale: this.globalPreferencesForm.language,
            defaultDisplayCurrency: this.globalPreferencesForm.currency,
            timezone: this.globalPreferencesForm.timezone
          }
          this.$store.dispatch('app/updateAgentUser', payload).then(
            ({ outlets, meta }) => {
              this.$store.commit('meta/SET_META_ACTION', meta)
              this.$store.commit('app/SET_APP', outlets.details)
              // set search many state
              this.$store.commit(
                'searchMany/SET_QUERY_PARAMS',
                { currency: this.globalPreferencesForm.currency }
              )
              this.initForm(outlets.details)
              this.globalPreferencesModalActive = false

              if (this.$i18n.locale !== outlets.details.defaultLocale) {
                this.$i18n.locale = outlets.details.defaultLocale
                this.$store.commit('user/SET_LOCALE', outlets.details.defaultLocale)
                window.location.reload()
              }
            }
          ).catch((err) => {
            this.globalPreferencesModalActive = false
            this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            })
          })
        }
      })
    },
    handleCancel () {
      this.$refs.globalPreferencesForm.resetFields()
      this.globalPreferencesModalActive = false
    },
    handleSaveGuestPreferences () {
      this.$refs.guestPreferencesForm.validate((valid) => {
        if (valid) {
          const payload = {
            defaultSourceMarket: this.guestPreferencesForm.nationalityObj.a2
          }
          this.$store.dispatch('app/updateAgentUser', payload).then(
            ({ outlets, meta }) => {
              this.$store.commit('meta/SET_META_ACTION', meta)
              this.$store.commit('app/SET_APP', outlets.details)
              // set search many state
              this.$store.commit(
                'searchMany/SET_QUERY_PARAMS',
                { nationality: this.guestPreferencesForm.nationalityObj.a2 }
              )
              this.initForm(outlets.details)
              this.guestPreferencesModalActive = false
            }
          ).catch((err) => {
            this.guestPreferencesModalActive = false
            this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            })
          })
        } else {
          // todo
          return false
        }
      })
    },
    handleCancelGuestPreferences () {
      this.$refs.guestPreferencesForm.resetFields()
      this.guestPreferencesModalActive = false
    }
  },
  metaInfo () {
    return {
      title: 'Account Preferences'
    }
  }
}
</script>
