<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div
          v-if="!isFetching"
          class="main-content-inner">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary m-b-4x">
            {{ $t('views.MyAccount.My Company') }}
          </h2>
          <div class="columns is-gapless m-b-0 section-header">
            <div class="column">
              <div>
                {{ $t('views.MyAccount.Company Info') }}
              </div>
            </div>
            <div class="column has-text-right">
              <a
                v-show="$hasRole('CLIENT_ADMIN')"
                class="has-text-white"
                @click.prevent="handleEditCompany">
                <IconBase
                  width="13"
                  height="13"
                  icon-name="edit">
                  <IconEdit />
                </IconBase>
                {{ $t('views.MyAccount.Edit') }}
              </a>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.company-name.label') }}
                </div>
                <div>{{ companyForm.companyName }}</div>
              </div>
            </div>
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.phone-number.label') }}
                </div>
                <div>{{ companyForm.contactNo }}</div>
              </div>
            </div>
            <div class="column is-5">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.accounts-email.label') }}
                </div>
                <div>{{ companyForm.accountsEmail || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-3x">
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.company-address.label') }}
                </div>
                <div>{{ companyForm.address }}</div>
              </div>
            </div>
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.city.label') }}
                </div>
                <div>{{ companyForm.city }}</div>
              </div>
            </div>
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.country.label') }}
                </div>
                <div>{{ $t(`countries.${getCountry(companyForm.country, 'a3')}`) }}</div>
              </div>
            </div>
            <div class="column is-2">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.postal-code.label') }}
                </div>
                <div>{{ companyForm.postalCode || '-' }}</div>
              </div>
            </div>
          </div>

          <!-- upload logo start-->
          <div
            v-if="$hasRole('CLIENT_ADMIN')"
            class="columns is-gapless m-b-0 section-header">
            <div class="column">
              <div>
                {{ $t('company-logo-upload.label') }}
              </div>
            </div>
          </div>
          <div
            v-if="$hasRole('CLIENT_ADMIN')"
            class="columns is-gapless columns-table m-b-3x">
            <div class="column is-12">
              <div class="td">
                <div class="desc m-b-2x">
                  {{ $t('company-logo-upload.desc') }}
                </div>
                <div class="m-b-4x">
                  <el-checkbox
                    v-if="logo && logo.url"
                    v-model="customLogo"
                    @change="handleCustomLogoChange">
                    Display logo in navigation bar.
                  </el-checkbox>
                </div>
                <div>
                  <LogoUpload
                    :value="logo"
                    @change="handleUploadCompanyLogo" />
                </div>
              </div>
            </div>
          </div>
          <!-- upload logo end-->

          <div class="columns is-gapless m-b-0 section-header">
            <div class="column">
              <div>
                {{ $t('views.MyAccount.Email template') }}
              </div>
            </div>
            <div class="column has-text-right">
              <a
                v-show="$hasRole('CLIENT_ADMIN')"
                class="has-text-white"
                @click.prevent="handleEditEmail">
                <IconBase
                  width="13"
                  height="13"
                  icon-name="edit">
                  <IconEdit />
                </IconBase>
                {{ $t('views.MyAccount.Edit') }}
              </a>
            </div>
          </div>
          <!-- <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">{{ $t('views.MyAccount.Email template') }}</div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click.prevent="editEmailTemplateActive=true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit/>
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div> -->
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.company-name.label') }}
                </div>
                <div>{{ emailTemplateForm.companyName }}</div>
              </div>
            </div>
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.email.label') }}
                </div>
                <div class="has-text-break-word">
                  {{ emailTemplateForm.emailSupport }}
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.phone-number.label') }}
                </div>
                <div>{{ emailTemplateForm.contactNo }}</div>
              </div>
            </div>
            <div class="column is-3">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  WhatsApp number
                </div>
                <div>{{ emailTemplateForm.whatsAppNo || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.cancellation-policy-email.label') }}
                </div>
                <div>{{ showCancellationPolicyEmail }}</div>
              </div>
            </div>
            <div class="column is-4">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.cancellation-policy-voucher.label') }}
                </div>
                <div>{{ showCancellationPolicyVoucher }}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- Edit modal -->
    <PpModal v-model="editModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="companyForm"
            :model="companyFormModal"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit company info') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.company-name.label')"
              class="column is-12"
              prop="companyName">
              <el-input
                v-model="companyFormModal.companyName"
                type="text"
                autocomplete="companyName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.country.label')"
              class="column is-12"
              prop="country">
              <InputNationality
                v-model.trim="companyFormModal.countryObj"
                :placeholder="$t('views.customer.form.country.placeholder')"
                style="width: 100%" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.accounts-email.label')"
              class="column is-12"
              prop="accountsEmail">
              <el-input
                v-model.trim="companyFormModal.accountsEmail"
                type="email" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.company-address.label')"
              class="column is-12"
              prop="address">
              <el-input
                v-model="companyFormModal.address"
                type="text"
                autocomplete="companyAddress" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.city.label')"
              class="column is-6"
              prop="city">
              <el-input
                v-model="companyFormModal.city"
                type="text"
                autocomplete="city" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.postal-code.label')"
              class="column is-6"
              prop="postalCode">
              <el-input
                v-model.trim="companyFormModal.postalCode"
                type="text"
                autocomplete="postalCode" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.country-code.label')"
              class="column is-4"
              prop="phoneCountryObj">
              <InputNationality
                v-model.trim="companyFormModal.phoneCountryObj"
                :placeholder="$t('views.customer.form.country-code.placeholder')"
                display-type="ph" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.phone-number.label')"
              class="column is-8"
              prop="phoneNumber">
              <el-input
                v-model.trim="companyFormModal.phoneNumber"
                type="tel"
                autocomplete="phoneNumber" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSave">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <!-- Edit email modal -->
    <PpModal v-model="editEmailTemplateActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="emailTemplateForm"
            :model="emailTemplateFormModal"
            :rules="emailTemplateRules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit email template') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.company-name.label')"
              class="column is-12"
              prop="companyName">
              <el-input
                v-model="emailTemplateFormModal.companyName"
                type="text"
                autocomplete="companyName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.email.label')"
              class="column is-12"
              prop="emailSupport">
              <el-input
                v-model.trim="emailTemplateFormModal.emailSupport"
                :placeholder="$t('views.customer.form.email.placeholder')"
                type="email"
                autocomplete="email" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.country-code.label')"
              class="column is-4"
              prop="phoneCountryObj">
              <InputNationality
                v-model.trim="emailTemplateFormModal.phoneCountryObj"
                :placeholder="$t('views.customer.form.country-code.placeholder')"
                display-type="ph" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.phone-number.label')"
              class="column is-4"
              prop="phoneNumber">
              <el-input
                v-model.trim="emailTemplateFormModal.phoneNumber"
                type="tel"
                autocomplete="phoneNumber" />
            </el-form-item>
            <el-form-item
              label="WhatsApp number"
              class="column is-4"
              prop="whatsAppNumber">
              <el-input
                v-model.trim="emailTemplateFormModal.whatsAppNumber"
                type="tel" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.cancellation-policy-email.label')"
              class="column is-6"
              prop="showCancellationPolicyEmail">
              <InputShow
                v-model.trim="emailTemplateFormModal.showCancellationPolicyEmail"
                :placeholder="$t('views.customer.form.cancellation-policy-email.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.cancellation-policy-voucher.label')"
              class="column is-6"
              prop="showCancellationPolicyVoucher">
              <InputShow
                v-model.trim="emailTemplateFormModal.showCancellationPolicyVoucher"
                :placeholder="$t('views.customer.form.cancellation-policy-voucher.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancelEmailTemplate">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSaveEmailTemplate">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { getCountryByAlpha2, getCountryByPhone } from '@/plugins/countriesHelper'
import IconEdit from '@/components/icons/IconEdit'
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'
import InputNationality from '@/components/InputNationality'
import InputShow from '@/components/InputShow'
import LogoUpload from '@/components/LogoUpload'

export default {
  name: 'MyCompany',
  components: {
    LogoUpload,
    InputNationality,
    MyAccountSidebar,
    InputShow,
    IconEdit
  },
  data () {
    return {
      isFetching: false,
      editModalActive: false,
      editEmailTemplateActive: false,
      companyForm: {},
      companyFormModal: {},
      emailTemplateFormModal: {},
      rules: {
        accountsEmail: [
          {
            type: 'email',
            message: this.$t('views.customer.form.accounts-email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }
        ],
        companyName: [
          {
            required: true,
            message: this.$t('views.customer.form.company-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        country: [
          {
            required: true,
            message: this.$t('views.customer.form.country.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        address: [
          {
            required: true,
            message: this.$t('views.customer.form.company-address.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        city: [
          {
            required: true,
            message: this.$t('views.customer.form.city.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        /* postalCode: [
          {
            required: true,
            message: this.$t('views.customer.form.postal-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ], */
        countryCode: [
          {
            required: true,
            message: this.$t('views.customer.form.country-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        phoneNumber: [
          {
            type: 'string',
            message: this.$t('views.customer.form.phone-number.invalid'),
            trigger: [
              'change', 'blur'
            ],
            pattern: /^\+?[1-9]\d{7,10}$/
          }, {
            required: true,
            message: this.$t('views.customer.form.phone-number.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      emailTemplateForm: {
        companyName: '',
        emailSupport: '',
        phoneCountryObj: {},
        phoneNumber: '',
        whatsAppNumber: '',
        whatsAppNo: '',
        contactNo: '',
        showCancellationPolicyEmail: '',
        showCancellationPolicyVoucher: ''
      },
      emailTemplateRules: {
        companyName: [
          {
            required: true,
            message: this.$t('views.customer.form.company-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        phoneCountryObj: [
          {
            required: true,
            message: this.$t('views.customer.form.country-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        phoneNumber: [
          {
            type: 'string',
            message: this.$t('views.customer.form.phone-number.invalid'),
            trigger: [
              'change', 'blur'
            ],
            pattern: /^\+?[1-9]\d{7,10}$/
          }, {
            required: true,
            message: this.$t('views.customer.form.phone-number.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        whatsAppNumber: [
          {
            type: 'string',
            message: 'Invalid  whatsApp number',
            trigger: [
              'change', 'blur'
            ],
            pattern: /^\+?[1-9]\d{7,10}$/
          }
        ],
        emailSupport: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      logo: undefined,
      customLogo: false
    }
  },
  computed: {
    companyInfo () {
      return this.$store.getters['app/companyInfo']
    },
    showCancellationPolicyEmail () {
      return this.cancellationPolicyLabel(this.emailTemplateForm.showCancellationPolicyEmail)
    },
    showCancellationPolicyVoucher () {
      return this.cancellationPolicyLabel(this.emailTemplateForm.showCancellationPolicyVoucher)
    }
  },
  created () {
    this.isFetching = true
    this.$store.commit('SHOW_FULLSCREEN_LOADER')
    this.$store.dispatch('app/getAppInfo').then(
      () => {
        this.initForm(this.$store.state.app)
        this.isFetching = false
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      }
    )
  },
  methods: {
    initForm (form) {
      this.companyForm = {
        ...this.companyForm,
        ...form,
        phoneCountryObj: this.phoneCountryCode(form.contactNo),
        phoneNumber: this.phoneNumber(form.contactNo),
        countryObj: getCountryByAlpha2(form.country)
      }
      this.emailTemplateForm = {
        ...this.emailTemplateForm,
        ...form.email,
        phoneCountryObj: this.phoneCountryCode(form.email.contactNo || ''),
        phoneNumber: this.phoneNumber(form.email.contactNo || ''),
        whatsAppNumber: this.phoneNumber(form.email.whatsAppNo || ''),
        showCancellationPolicyEmail: form.email.showCancellationPolicyEmail,
        showCancellationPolicyVoucher: form.email.showCancellationPolicyVoucher
      }
      if (form.hasOwnProperty('images') && form.images.hasOwnProperty('mail') && form.images.hasOwnProperty('customLogo')) {
        this.logo = { ...this.logo, ...form.images.mail }
        this.customLogo = form.images.customLogo
      }
    },
    getCountry (a2, key) {
      return getCountryByAlpha2(a2)[key]
    },
    handleSave () {
      this.$refs.companyForm.validate((valid) => {
        if (valid) {
          const { phoneCountryObj, countryObj, phoneNumber, ...rest } = this.companyFormModal
          const payload = {
            ...rest,
            contactNo: `(${phoneCountryObj.ph}) ${phoneNumber}`,
            country: countryObj.a2
          }

          this.updateCompany('app/updateCompanyInfo', payload)

          return true
        } else {
          return false
        }
      })
    },
    handleSaveEmailTemplate () {
      this.$refs.emailTemplateForm.validate((valid) => {
        if (valid) {
          this.updateCompany('app/updatedEmailTemplate', {
            companyName: this.emailTemplateFormModal.companyName,
            contactNo: `(${this.emailTemplateFormModal.phoneCountryObj.ph}) ${this.emailTemplateFormModal.phoneNumber}`,
            emailSupport: this.emailTemplateFormModal.emailSupport,
            showCancellationPolicyEmail: this.emailTemplateFormModal.showCancellationPolicyEmail,
            showCancellationPolicyVoucher: this.emailTemplateFormModal.showCancellationPolicyVoucher,
            ...{ whatsAppNo: this.emailTemplateFormModal.whatsAppNumber ? `(${this.emailTemplateFormModal.phoneCountryObj.ph}) ${this.emailTemplateFormModal.whatsAppNumber}` : '' }
          })
        }
      })
    },
    updateCompany (path, payload) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER', 'saving')
      this.$store.dispatch(path, payload)
        .then(({ outlets, meta }) => {
          this.$store.commit('meta/SET_META_ACTION', meta)
          this.$store.commit('app/SET_APP', outlets.details)
          this.initForm(outlets.details)
          this.editModalActive = false
          this.editEmailTemplateActive = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
        })
        .catch((err) => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.editModalActive = false
          this.editEmailTemplateActive = false
          this.$store.commit('meta/SET_META_ACTION', { meta: err.meta })
          this.$store.commit('SET_GLOBAL_MODAL_ERROR', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        })
    },
    handleCancel () {
      this.$refs.companyForm.resetFields()
      this.editModalActive = false
    },
    handleCancelEmailTemplate () {
      this.$refs.emailTemplateForm.resetFields()
      this.editEmailTemplateActive = false
    },
    handleEditCompany () {
      this.companyFormModal = { ...this.companyForm }
      this.editModalActive = true
    },
    handleEditEmail () {
      this.emailTemplateFormModal = { ...this.emailTemplateForm }
      this.editEmailTemplateActive = true
    },
    phoneCountryCode (contactNo) {
      const prefix = contactNo.split(' ')[0]
      // remove ( )
      const normalized = prefix.replace(/\(/g, '').replace(/\)/g, '')
      return getCountryByPhone(normalized)
    },
    phoneNumber (contactNo) {
      const phoneNumber = contactNo.split(' ')[1]
      return phoneNumber
    },
    handleUploadCompanyLogo (logoObj) {
      this.updateCompany('app/updateCompanyLogo', {
        ...logoObj,
        custom: logoObj.url ? this.customLogo : false
      })
    },
    handleCustomLogoChange () {
      if (this.logo && this.logo.url) {
        this.handleUploadCompanyLogo(this.logo)
      }
    },
    cancellationPolicyLabel (show) {
      if (typeof show === 'undefined') show = true
      return show ? this.$t('showOptions.yes') : this.$t('showOptions.no')
    }
  },
  metaInfo () {
    return {
      title: 'My Company'
    }
  }
}
</script>
