<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div class="main-content-inner">
          <div class="columns">
            <div class="column">
              <h2 class="has-text-weight-bold is-size-3 has-text-primary">
                {{ $t('views.MyAccount.Users') }}
              </h2>
            </div>
            <div class="column is-narrow">
              <el-button
                v-if="$hasRole('CLIENT_ADMIN')"
                class="button-secondary"
                style="min-width: 140px;"
                @click="inviteModalActive=true">
                {{ $t('views.MyAccount.Invite User') }}
              </el-button>
            </div>
          </div>
          <el-table
            ref="singleTable"
            :data="userArraySlice"
            stripe
            style="width: 100%">
            <el-table-column
              type="index"
              width="50" />
            <el-table-column :label="$t('views.MyBookings.User')">
              <template slot-scope="scope">
                {{ scope.row.displayName }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('views.customer.form.email.label')"
              property="email" />
            <el-table-column :label="$t('views.MyAccount.Role')">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.roleCode"
                  :disabled="scope.row.uid === loggedInUid || disabledAction"
                  @change="updateUser(scope.row)">
                  <el-option
                    v-for="ro in roleOptions"
                    :key="ro.value"
                    :value="ro.value"
                    :label="ro.label" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('views.MyAccount.Status')"
              width="100">
              <template slot-scope="scope">
                <el-switch
                  :value="!scope.row.inactive"
                  :disabled="scope.row.uid === loggedInUid || disabledAction"
                  @change="handleStatusChanged(scope.row)" />
              </template>
            </el-table-column>
            <el-table-column width="50">
              <template slot-scope="scope">
                <el-link
                  :disabled="scope.row.uid === loggedInUid || disabledAction"
                  icon="el-icon-delete"
                  type="danger"
                  @click="updateUser(scope.row, true)" />
              </template>
            </el-table-column>
          </el-table>
          <div class="column is-12-mobile has-text-centered-mobile has-text-right">
            <el-pagination
              v-if="users && users.length > 10"
              :pager-count="5"
              :page-size="10"
              :current-page="pagination.page"
              :total="users.length"
              background
              layout="prev, pager, next"
              @current-change="(page) => pagination.page = page" />
          </div>
        </div>
      </main>
    </div>
    <!-- Invite modal -->
    <PpModal v-model="inviteModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="inviteForm"
            :model="inviteForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Invite User') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.first-name.label')"
              class="column is-6"
              prop="firstName">
              <el-input
                v-model.trim="inviteForm.firstName"
                :placeholder="$t('views.customer.form.first-name.placeholder')"
                autocomplete="firstName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.last-name.label')"
              class="column is-6"
              prop="lastName">
              <el-input
                v-model.trim="inviteForm.lastName"
                :placeholder="$t('views.customer.form.last-name.placeholder')"
                autocomplete="lastName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.email.label')"
              class="column is-6"
              prop="email">
              <el-input
                v-model.trim="inviteForm.email"
                :placeholder="$t('views.customer.form.email.placeholder')"
                type="email"
                autocomplete="email" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.role-code.label')"
              prop="roleCode"
              class="column is-6">
              <el-select
                v-model="inviteForm.roleCode"
                :placeholder="$t('views.customer.form.role-code.placeholder')"
                style="width: 100%">
                <el-option
                  v-for="ro in roleOptions"
                  :key="ro.value"
                  :value="ro.value"
                  :label="ro.label" />
              </el-select>
            </el-form-item>
            <div
              v-if="errMsg !== null"
              class="error-box">
              <el-alert
                :title="errMsg"
                :closable="false"
                type="error" />
            </div>
            <div class="column is-12">
              {{ $t('views.MyAccount.invite-user-desc',{company: companyName}) }}
            </div>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                :loading="isSubmitting"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleInvite">
                {{ $t('views.customer.form.Send Invitation') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { retrieveAgent, retrieveUser } from '@/service/auth'
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'

export default {
  name: 'Users',
  components: {
    MyAccountSidebar
  },
  data () {
    return {
      inviteModalActive: false,
      isSubmitting: false,
      users: [],
      errMsg: null,
      inviteForm: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        roleCode: undefined
      },
      pagination: {
        page: 1
      },
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t('views.customer.form.first-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        roleCode: [
          {
            required: true,
            message: this.$t('views.customer.form.role-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      roleOptions: [
        { label: this.$t('views.MyAccount.roleOptions.company-admin'), value: 'CLIENT_ADMIN' },
        { label: this.$t('views.MyAccount.roleOptions.agent'), value: 'CLIENT_AGENT_SBC' },
        { label: this.$t('views.MyAccount.roleOptions.itc'), value: 'CLIENT_AGENT_SB' },
        { label: this.$t('views.MyAccount.roleOptions.finance'), value: 'CLIENT_FINANCE' }
      ]
    }
  },
  computed: {
    userArraySlice () {
      const SIZE = 10
      const { page } = this.pagination
      if (!this.users || !Array.isArray(this.users)) return []
      /**
       * pagination by FE,
       * simply return users data by page
       */
      return this.users.slice(
        (page - 1) * SIZE,
        page * SIZE
      )
    },
    companyName () {
      return retrieveAgent('companyName')
    },
    loggedInUid () {
      return retrieveUser('uid')
    },
    disabledAction () {
      return !this.$hasRole('CLIENT_ADMIN')
    }
  },
  created () {
    this.fetchUsers()
  },
  methods: {
    fetchUsers () {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('user/fetchUsers').then(
        ({ outlets, meta }) => {
          this.$store.commit('meta/SET_META_ACTION', meta)
          this.users = outlets.items || []
        }
      ).catch((err) => {
        this.$store.commit('meta/SET_META_ACTION', err.meta)
        this.$store.commit(
          'SET_GLOBAL_ERROR_MODAL',
          { show: true, content: err.message || (err.error && err.error.message) || '' }
        )
      }).finally(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      })
    },
    resetForm () {
      this.inviteForm.email = undefined
      this.inviteForm.firstName = undefined
      this.inviteForm.lastName = undefined
      this.inviteForm.roleCode = undefined
    },
    handleInvite () {
      this.errMsg = null
      this.$refs.inviteForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          const payload = {
            email: this.inviteForm.email,
            firstName: this.inviteForm.firstName,
            lastName: this.inviteForm.lastName,
            roleCode: this.inviteForm.roleCode
          }
          this.$store.commit('SHOW_FULLSCREEN_LOADER')
          this.$store.dispatch('user/invite', payload).then(
            (resp) => {
              this.isSubmitting = false
              this.$store.commit('HIDE_FULLSCREEN_LOADER')
              this.inviteModalActive = false
              this.resetForm()
              this.fetchUsers()
            }
          ).catch((err) => {
            this.isSubmitting = false
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.errMsg = err.message || (err.error && err.error.message) || ''
          })
        }
      })
    },
    handleCancel () {
      this.$refs.inviteForm.resetFields()
      this.inviteModalActive = false
    },
    removeUser (user) {
      this.$confirm(this.$t('views.MyAccount.delete-user-message'), this.$t('views.MyAccount.delete-user-title'), {
        confirmButtonText: this.$t('views.customer.form.submit'),
        cancelButtonText: this.$t('views.customer.form.cancel'),
        cancelButtonClass: 'button-secondary-outline',
        confirmButtonClass: 'button-secondary',
        customClass: 'warning-message-box',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.users = this.users.filter(v => v !== user)
      }).catch(() => {
        // todo
      })
    },
    handleStatusChanged (user) {
      user.inactive = !user.inactive
      this.updateUser(user)
    },
    updateUser (user, isDelete = null) {
      const payload = {
        email: user.email,
        uid: user.uid,
        roleCode: user.roleCode,
        inactive: user.inactive,
        isDelete
      }
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('user/updateUser', payload).then(
        () => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          if (isDelete) {
            this.fetchUsers()
          }
        }
      ).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('meta/SET_META_ACTION', err.meta)
        this.$store.commit(
          'SET_GLOBAL_ERROR_MODAL',
          { show: true, content: err.message || (err.error && err.error.message) || '' }
        )
      })
    }
  },
  metaInfo () {
    return {
      title: 'Users'
    }
  }
}
</script>
