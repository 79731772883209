<template>
  <div>
    <div class="columns is-align-middle m-b-5x">
      <div class="column is-size-5 has-text-primary has-text-weight-bold">
        Found {{ totalItems }} bookings in total.
      </div>
      <div
        v-if="selectedTotal"
        class="column has-text-right">
        <span class="total-selected">{{ $t('Total amount selected') }} <strong>{{ selectedTotal|currencyObj }}</strong></span>
        <el-button
          class="button-secondary"
          @click="handlePayment">
          {{ $t('Pay Selected Bookings') }}
        </el-button>
      </div>
    </div>
    <div
      v-if="items.length"
      class="columns">
      <el-table
        ref="singleTable"
        :data="items"
        stripe
        style="width: 100%"
        class="settle-table"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55" />
        <el-table-column
          :label="$t('Booking ID')"
          property="order.orderReference" />
        <el-table-column
          :label="$t('Property')"
          property="property.name" />
        <el-table-column
          :label="$t('Agent')"
          property="order.createdBy" />
        <el-table-column
          :label="$t('Payment deadline')">
          <template slot-scope="scope">
            {{ scope.row.payment.metadata.payLaterDueDate|dateTz({ tz: 'Africa/Johannesburg' }) }} SAST
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('Booking Amount')">
          <template slot-scope="scope">
            {{ scope.row.order.agentActualChargeAmount | currencyObj }}
          </template>
        </el-table-column>
        <el-table-column
          label=" ">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'my-bookings-detail', params: {orderReference: scope.row.order.orderReference} }"
              class="has-text-secondary">
              {{ $t('see details') }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          label=" ">
          <template slot-scope="scope">
            <router-link
              :to="{name:'bulk-payment',params:{orderReference: scope.row.order.orderReference}}"
              class="has-text-secondary has-text-weight-bold">
              {{ $t('PAY') }}
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-else
      class="columns">
      <div class="column is-12">
        <EmptyState :message="$t('views.MyBookings.no-bookings-found')" />
      </div>
    </div>

    <Pagination
      :total-items="totalItems"
      :page="page"
      :page-size="pageSize"
      :pager-count="5"
      top-element="#order-list"
      @change="handlePageChange" />
  </div>
</template>
<script>

import Pagination from '@/components/Pagination'
import { plus } from 'number-precision'

export default {
  name: 'SettlePaymentHistory',
  components: {
    Pagination
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    totalItems: {
      type: Number,
      default: 0
    },
    filters: {
      type: Object,
      default: () => {}
    },
    pageSize: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'Booking ID': 'Booking ID',
        'Property': 'Property',
        'Agent': 'Agent',
        'Payment deadline': 'Payment deadline',
        'Booking Amount': 'Booking Amount',
        'see details': 'see details',
        'PAY': 'PAY',
        'Total amount selected': 'Total amount selected:',
        'Pay Selected Bookings': 'Pay Selected Bookings'
      }
    }
  },
  data () {
    return {
      selectedOrder: []
    }
  },
  computed: {
    selectedTotal () {
      if (!this.selectedOrder.length) return null
      let total = 0
      let currency = ''
      this.selectedOrder.forEach((item) => {
        total = plus(total, item.order.agentActualChargeAmount.value)
        if (!currency) currency = item.order.agentActualChargeAmount.currency
      })
      return {
        value: total,
        currency: currency
      }
    }
  },
  methods: {
    handlePageChange ({ page }) {
      this.$emit('historyPageChanged', { page: page })
    },
    handleSelectionChange (val) {
      this.selectedOrder = val
    },
    handlePayment () {
      let orderReference = this.selectedOrder.map((i) => i.order.orderReference)
      this.$router.push({ name: 'bulk-payment', params: { orderReference: orderReference.join(',') } })
    }
  }
}
</script>
<style lang="scss" scoped>
  .pagi-con {
    align-items: center;
  }

  .pagi {
    padding: 0 !important;
  }
  .settle-table ::v-deep td {
    cursor: default;
  }
  .total-selected {
    display: inline-block;
    margin-right: 1rem;
  }
</style>
