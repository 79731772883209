<template>
  <el-select
    v-model="localValue"
    filterable
    autocomplete="off"
    :placeholder="placeholder">
    <el-option
      v-for="it in languageOptions"
      :key="it.value"
      :value="it.value"
      :label="it.label" />
  </el-select>
</template>

<script>

export default {
  name: 'InputLanguage',
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Language'
    }
  },
  data () {
    return {
      'languageOptions': [
        {
          label: this.$t('languageOptions.en-US'),
          value: 'en-US'
        }
        // {
        //   label: this.$t('languageOptions.zh-CN'),
        //   value: 'zh-CN'
        // }
      ]
    }
  },
  computed: {
    localValue: {
      set (v) {
        this.$emit('input', v)
        return v
      },
      get () {
        return this.value
      }
    }
  }
}
</script>

<style scoped>

</style>
