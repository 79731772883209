<template>
  <div>
    <div class="columns is-align-middle m-b-5x">
      <div class="column is-size-5 has-text-primary has-text-weight-bold">
        {{ $t('views.MyBookings.found',{count:totalItems}) }}
      </div>
      <div class="column has-text-right">
        <el-button
          type="primary"
          @click="exportModalActive=true">
          {{ $t('views.MyBookings.Export All') }}
        </el-button>
      </div>
    </div>
    <div
      v-if="items.length"
      class="columns is-multiline">
      <div
        v-for="item in items"
        :key="item.orderReference"
        class="column is-12  has-text-ellipsis">
        <MyBookingsCard :item="item" />
      </div>
    </div>
    <div
      v-else
      class="columns">
      <div class="column is-12">
        <EmptyState :message="$t('views.MyBookings.no-bookings-found')" />
      </div>
    </div>

    <Pagination
      :total-items="totalItems"
      :page="page"
      :page-size="pageSize"
      :pager-count="5"
      top-element="#order-list"
      @change="handlePageChange" />

    <PpModal v-model="exportModalActive">
      <PpCard class="box has-border is-flex">
        <div class="export-modal-content">
          <div class="m-b-5x is-size-4 has-text-secondary has-text-weight-bold">
            {{ $t('views.MyBookings.Confirm to Export') }}
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ dateRangeLabel }}
              </div>
              <div class="has-text-weight-bold">
                {{ `${filters.dateRange.checkInDate} - ${filters.dateRange.checkOutDate}` }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.guestName.label') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.guestName ||'-' }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.orderReference.label') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.orderReference ||'-' }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.agentName.placeholder') }}
              </div>
              <div class="has-text-weight-bold">
                {{ agents }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.hotelName.label') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.hotelName ||'-' }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.status.placeholder') }}
              </div>
              <div class="has-text-weight-bold">
                {{ orderStatusesString }}
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="has-text-grey-light">
                {{ $t('views.MyBookings.form.agentReference.label') }}
              </div>
              <div class="has-text-weight-bold">
                {{ filters.agentReference ||'-' }}
              </div>
            </div>
            <div class="column">
              <div class="has-text-grey-light">
                Commission status
              </div>
              <div class="has-text-weight-bold">
                {{ agentEarningStatusString ||'-' }}
              </div>
            </div>
          </div>
          <div
            style="margin-top: 60px;"
            class="has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="exportModalActive=false">
              {{ $t('views.MyBookings.Cancel') }}
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 155px;"
              @click="handleExport">
              {{ $t('views.MyBookings.Export') }}
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>
<script>
import MyBookingsCard from '@/components/MyBookingsCard'
import Pagination from '@/components/Pagination'

export default {
  name: 'MyBookingsHistory',
  components: {
    MyBookingsCard,
    Pagination
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    totalItems: {
      type: Number,
      default: 0
    },
    filters: {
      type: Object,
      default: () => {}
    },
    pageSize: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      exportModalActive: false
    }
  },
  // computed: {
  //   showingNow () {
  //     return `${3 * this.page - 2} – ${Math.min(this.page * 3, this.totalItems)} of ${this.totalItems}` // fix WL-96
  //   }
  // },
  computed: {
    orderStatusesString () {
      let str = this.filters.orderStatuses && this.filters.orderStatuses.length > 0 ? this.filters.orderStatuses.join(', ') : ''
      return str.length ? str : 'All'
    },
    agentEarningStatusString () {
      let str = this.filters.agentEarningStatus && this.filters.agentEarningStatus.length > 0 ? this.filters.agentEarningStatus.join(', ') : ''
      return str.length ? str : 'All'
    },
    agents () {
      if (this.filters.users.length) {
        let agents = this.filters.users.map(uid => {
          let agent = this.$store.getters['order/getAgentByUid'](uid)
          return agent.displayName
        })
        return agents.join(',')
      }
      return '-'
    },
    dateRangeLabel () {
      const getDateRangeLabel = (val) => {
        switch (val) {
        case 'checkout-date':
          return this.$t('views.CheckoutFormSummary.check-out-date')
        case 'booking-date':
          return this.$t('views.MyBookings.booking-date')
        default:
          return this.$t('views.CheckoutFormSummary.check-in-date')
        }
      }
      return this.filters.dateRangeType ? getDateRangeLabel(this.filters.dateRangeType) : this.$t('views.CheckoutFormSummary.check-in-date')
    }
  },
  methods: {
    handlePageChange ({ page, limit }) {
      this.$emit('historyPageChanged', { page, limit })
    },
    handleExport () {
      this.$emit('exported', '')
      this.exportModalActive = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .pagi-con {
    align-items: center;
  }

  .pagi {
    padding: 0 !important;
  }

  .export-modal-content {
    width: 600px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
</style>
