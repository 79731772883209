<template>
  <el-tooltip
    placement="bottom">
    <span
      class="copy-handler has-text-secondary has-text-weight-bold"
      @click="handleCopy()">copy booking detail</span>
    <div
      slot="content"
      v-html="getDetail()" />
  </el-tooltip>
</template>

<script>
export default {
  name: 'OrderDetailCopier',
  props: {
    orderReference: {
      type: String,
      required: true
    },
    propertyName: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    roomCount: {
      type: Number,
      required: true
    },
    nights: {
      type: Number,
      required: true
    },
    checkIn: {
      type: String,
      required: true
    },
    checkOut: {
      type: String,
      required: true
    },
    leadGuest: {
      type: String,
      required: true
    },
    adultCount: {
      type: Number,
      required: true
    },
    childrenCount: {
      type: Number,
      required: true
    }
  },
  methods: {
    getDetail (br = '<br>') {
      return `Order reference: ${this.orderReference} ${br}Property Name: ${this.propertyName} ${br}Address: ${this.address} ${br}Reservation: ${this.$options.filters.roomLabel(this.roomCount)}, ${this.$options.filters.nightLabel(this.nights)} ${br}Travel date:${this.$options.filters.date(this.checkIn)} - ${this.$options.filters.date(this.checkOut)} ${br}Lead guest: ${this.leadGuest} ${br}Occupancy: ${this.$options.filters.adultLabel(this.adultCount)}, ${this.childrenCount} children`
    },
    handleCopy () {
      this.$copyText(this.getDetail('\r\n')).then(() => {
        this.$message({
          type: 'success',
          message: `Booking detail was successful copied to clipboard`
        })
      }, () => {
        this.$message({
          type: 'error',
          message: `Oops, unable to copy`
        })
      })
    }
  }
}
</script>

<style scoped>
.copy-handler {
  cursor: pointer;
}
</style>
