<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div
          class="main-content-inner"
          style="max-width: 950px">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary m-b-4x">
            {{ $t('views.MyAccount.Credit Card') }}
          </h2>

          <div class="columns">
            <div class="column">
              <DateRangFilterForm
                :hide-top-up="true"
                @searched="handleFilterApply" />
            </div>
            <div class="column is-narrow">
              <el-button
                :loading="isLoading"
                :disabled="!items.length"
                type="primary"
                style="min-width: 140px;"
                @click="handleExport">
                {{ $t('views.MyAccount.Export to Excel') }}
              </el-button>
            </div>
          </div>
          <div
            v-if="isFetching"
            class="column has-text-center m-t-2x">
            <PpGap size="large" />
            <PpSpiner />
          </div>
          <h2
            v-if="!items.length && !isFetching"
            class="has-text-centered is-size-5">
            {{ $t('components.StaticDataAutosuggest.query.no-data-text') }}
          </h2>
          <el-table
            v-if="!isFetching && items.length"
            ref="singleTable"
            :data="items"
            stripe
            style="width: 100%">
            <el-table-column
              :label="$t('views.MyAccount.Timestamp')"
              width="180">
              <template slot-scope="scope">
                {{ formatDate(scope.row.createdAt) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('views.MyBookings.User')">
              <template slot-scope="scope">
                {{ scope.row.displayName }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('views.MyAccount.Action')">
              <template slot-scope="scope">
                {{ $t(`views.MyAccount.action.${scope.row.action}`) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('views.MyAccount.Method')"
              property="method">
              <template slot-scope="scope">
                <div style="width: 120px">
                  {{ scope.row.metadata.paymentMethod | paymentMethodLabel }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('views.MyAccount.Amount')"
              align="right">
              <template slot-scope="scope">
                <span :class="`${scope.row.action === 'booking' ?'has-text-danger':'has-text-success'}`">
                  {{ formatCcy(scope.row) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('views.MyAccount.Currency')">
              <template slot-scope="scope">
                <div style="width: 35px">
                  {{ scope.row.currency }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              :label="$t('views.MyAccount.Order Reference')">
              <template slot-scope="scope">
                <div
                  :title="scope.row.orderReference"
                  class="has-text-ellipsis">
                  {{ scope.row.metadata.remark }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="column is-12-mobile has-text-centered-mobile has-text-right">
            <el-pagination
              v-if="totalItems > 10"
              :pager-count="5"
              :page-size="10"
              :current-page="query.page"
              :total="totalItems"
              class="pagi"
              background
              layout="prev, pager, next"
              @current-change="handlePageChange" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { retrieveAgent } from '@/service/auth'
import moment from 'moment-timezone'
import dates from '@/plugins/dates'
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'
import DateRangFilterForm from '@/views/customer/DateRangFilterForm'
export default {
  name: 'CreditCard',
  components: {
    MyAccountSidebar,
    DateRangFilterForm
  },
  filters: {
    paymentMethodLabel: (val) => {
      switch (val) {
      case 'mc':
        return 'Mastercard'
      case 'visa':
        return 'Visa'
      case 'amex':
        return 'American Express'
      case 'jcb':
        return 'JCB'
      default: return val
      }
    }
  },
  data () {
    return {
      items: [],
      totalItems: 0,
      query: {
        page: 1,
        action: undefined,
        date: undefined,
        type: 'creditcard'
      },
      isFetching: false,
      isLoading: false
    }
  },
  created () {
    // window.moment = require('moment')
    // window.moment.tz = require('moment-timezone/builds/moment-timezone-with-data.min')
    this.fetchData(this.query)
  },
  methods: {
    formatCcy (item) {
      if (!item) return 'N/A'

      let amount = item.amount
      if (item.orderReference) {
        amount = 0 - amount
      }
      return `${item.action === 'booking' ? '-' : '+'}${parseFloat(Number(amount) / 100).toFixed(2)}`
    },
    formatDate (date) {
      return moment(date).tz(retrieveAgent('timezone')).format('MMM DD, YYYY HH:mm')
    },
    async fetchData (query) {
      try {
        this.isFetching = true
        const { outlets } = await this.$store.dispatch('app/fetchTransactions', query)

        this.items = outlets.items
        this.totalItems = outlets.totalItems
        this.isFetching = false
      } catch (err) {

      }
    },
    handlePageChange (pg) {
      this.query.page = pg
      this.fetchData(this.query)
    },
    handleFilterApply (val) {
      this.query.action = val.action || undefined
      if (val.dateRange.checkInDate && val.dateRange.checkOutDate) {
        const serializedDate = dates.fullDayRange([val.dateRange.checkInDate, val.dateRange.checkOutDate]).map(e => e.toJSON()).join('|')
        this.query.date = serializedDate !== '|' ? serializedDate : undefined
      }
      this.fetchData(this.query)
    },
    handleExport () {
      this.isLoading = true
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('app/exportTransaction', this.query)
        .then(({ data, headers }) => {
          this.isLoading = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          const contentDisposition = headers['content-disposition']
          const filename = contentDisposition.replace('attachment; filename=', '')
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          const url = window.URL.createObjectURL(data)
          a.href = url
          a.download = filename
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }).catch((err) => {
          this.isLoading = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$store.commit('SET_GLOBAL_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        })
    }
  },
  metaInfo () {
    return {
      title: 'Credit Card Transaction'
    }
  }
}
</script>

<style scoped>

</style>
