<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div class="main-content-inner">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary m-b-4x">
            {{ $t('views.MyAccount.My Account') }}
          </h2>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('views.MyAccount.Profile') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click.prevent="editModalActive=true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit />
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.first-name.label') }}
                </div>
                <div>{{ profileForm.firstName }}</div>
              </div>
            </div>
            <div class="column">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.last-name.label') }}
                </div>
                <div>{{ profileForm.lastName }}</div>
              </div>
            </div>
            <div class="column">
              <div class="td">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.customer.form.email.label') }}
                </div>
                <div>{{ profileForm.email }}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- Edit modal -->
    <PpModal v-model="editModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="profileForm"
            :model="profileForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit profile') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.first-name.label')"
              class="column is-6"
              prop="firstName">
              <el-input
                v-model.trim="profileForm.firstName"
                :placeholder="$t('views.customer.form.first-name.placeholder')"
                autocomplete="firstName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.last-name.label')"
              class="column is-6"
              prop="lastName">
              <el-input
                v-model.trim="profileForm.lastName"
                :placeholder="$t('views.customer.form.last-name.placeholder')"
                autocomplete="lastName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.email.label')"
              class="column is-12"
              prop="email">
              <el-input
                v-model.trim="profileForm.email"
                :placeholder="$t('views.customer.form.email.placeholder')"
                type="email"
                autocomplete="email"
                disabled />
            </el-form-item>
            <div
              v-if="errMsg !== null"
              class="error-box m-t-3x">
              <el-alert
                :title="errMsg"
                :closable="false"
                type="error" />
            </div>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSave">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import { retrieveUser } from '@/service/auth'
import IconEdit from '@/components/icons/IconEdit'
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'

export default {
  name: 'MyAccount',
  components: {
    MyAccountSidebar,
    IconEdit
  },
  data () {
    return {
      editModalActive: false,
      errMsg: null,
      profileForm: {
        email: '',
        firstName: '',
        lastName: ''
      },
      rules: {
        firstName: [
          {
            required: true,
            message: this.$t('views.customer.form.first-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        lastName: [
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },
  computed: {
    profile () {
      return retrieveUser()
    }
  },
  created () {
    this.profileForm.firstName = this.profile.firstName
    this.profileForm.lastName = this.profile.lastName
    this.profileForm.email = this.profile.email
  },
  methods: {
    handleSave () {
      this.errMsg = null
      this.$refs.profileForm.validate((valid) => {
        if (valid) {
          const payload = {
            uid: this.profile.uid,
            firstName: this.profileForm.firstName,
            lastName: this.profileForm.lastName,
            roleCode: this.profile.roleCode,
            inactive: this.profile.inactive,
            isDelete: null
          }
          this.$store.commit('SHOW_FULLSCREEN_LOADER', 'saving')
          this.$store.dispatch('user/updateUser', payload).then(
            ({ outlets }) => {
              this.$store.dispatch('user/updateLocalStorage', outlets.details)
              this.editModalActive = false
              this.$store.commit('HIDE_FULLSCREEN_LOADER')
            }
          ).catch((err) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.errMsg = err.message || (err.error && err.error.message) || ''
          })
        } else {
          // todo
          return false
        }
      })
    },
    handleCancel () {
      this.$refs.profileForm.resetFields()
      this.editModalActive = false
    }
  },
  metaInfo () {
    return {
      title: 'My Account'
    }
  }
}
</script>
