<template>
  <table class="table">
    <thead>
      <tr>
        <th>{{ $t('views.MyBookings.Cancellation policy') }}</th>
        <th>{{ $t('views.MyBookings.Fee') }}</th>
        <th>{{ $t('views.MyBookings.Fee') + ' %' }}</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(policy, idx) in localPolicies"
        :key="idx">
        <td>
          <span v-if="policy.penalty_percentage === 0">
            {{ $t('views.MyBookings.Before') }}
            {{ formatDate(policy.date_to) }}
          </span>
          <span v-else>
            {{ $t('views.MyBookings.From') }}
            {{ formatDate(policy.date_from) }}
          </span>
          <el-tooltip content="">
            <div slot="content">
              GMT/UTC +00:00
            </div>
            <i class="el-icon-info has-text-secondary" />
          </el-tooltip>
        </td>
        <td>
          {{ policy.penalty_percentage === 0 ? $t('views.MyBookings.Free') : `${totalAmount.currency} ${totalAmount.value.toFixed(2)}` }}
        </td>
        <td>
          {{ `${policy.penalty_percentage}%` }}
        </td>
        <td
          v-if="idx === localPolicies.length - 1"
          colspan="2"
          class="has-text-right">
          <el-button
            v-if="showCancelBtn"
            type="danger"
            @click="onCancel">
            {{ $t('views.MyBookings.Cancel Booking') }}
          </el-button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex'
import dates from '@/plugins/dates'

export default {
  name: 'CancellationPolicies',
  props: {
    cancellationPolicies: {
      type: Array,
      default: () => [],
      required: true
    },
    freeCancellationBefore: {
      type: String
    },
    totalAmount: {
      type: Object,
      default: () => {
        return { value: 0, currency: 'USD' }
      },
      required: true
    },
    bookingCreated: {
      type: String,
      required: true
    },
    showCancelBtn: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(['cancellationBuffer']),
    localPolicies () {
      const cxp = []
      let zeroRefund
      let fullRefund
      if (this.freeCancellationBefore) {
        fullRefund = {
          penalty_percentage: 0,
          date_from: this.bookingCreated,
          date_to: this.freeCancellationBefore
        }
        zeroRefund = {
          penalty_percentage: 100,
          date_from: this.freeCancellationBefore
        }
      } else if (!this.freeCancellationBefore) {
        zeroRefund = {
          penalty_percentage: 100,
          date_from: this.bookingCreated
        }
      }

      if (fullRefund) {
        cxp.push(fullRefund)
      }

      if (zeroRefund) {
        cxp.push(zeroRefund)
      }

      return cxp
    }
  },
  methods: {
    formatDate (date) {
      return dates.formatUTC(date)
    },
    onCancel () {
      this.$confirm(
        this.$t('views.MyBookings.confirm-message', {
          ammout: this.$options.filters.currencyObj({
            currency: this.totalAmount.currency,
            value: dates.isRefundable(this.freeCancellationBefore) ? 0 : this.totalAmount.value
          })
        }),
        this.$t('views.MyBookings.confirm-title'), {
          confirmButtonText: this.$t('views.MyBookings.confirm-submit'),
          cancelButtonText: this.$t('views.MyBookings.confirm-cancel'),
          cancelButtonClass: 'button-secondary-outline',
          confirmButtonClass: 'button-secondary',
          customClass: 'warning-message-box',
          dangerouslyUseHTMLString: true
        }
      )
        .then(() => this.$emit('cancelBooking'))
        .catch((err) => {
          if (err !== 'cancel' && (err.error || err.message)) {
            this.$store.commit('SET_GLOBAL_MODAL', {
              show: true,
              content: this.$t(`error.${err.error && err.error.code}`) || err.message || (err.error && err.error.message) || ''
            })
          }
        })
    }
  }
}
</script>
