<template>
  <el-select
    v-model="localValue"
    :placeholder="placeholder"
    filterable
    autocomplete="off">
    <el-option
      v-for="it in timezoneOptions"
      :key="it"
      :value="it"
      :label="it" />
  </el-select>
</template>

<script>
import { getTimezoneOptions } from '@/plugins/timezoneHelper'

export default {
  name: 'InputTimeZone',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Timezone'
    }
  },
  data () {
    return {
      timezoneOptions: getTimezoneOptions()
    }
  },
  computed: {
    localValue: {
      set (v) {
        console.log(v)
        this.$emit('input', v)
        return v
      },
      get () {
        return this.value
      }
    }
  }
}
</script>
