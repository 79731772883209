<template>
  <aside class="sidebar">
    <div class="sidebar-inner">
      <ul class="sidebar-menu">
        <li :class="isActive('my-account')">
          <router-link to="/my-account">
            {{ $t('views.MyAccount.My Account') }}
          </router-link>
        </li>
        <li :class="isActive('my-company')">
          <router-link to="/my-company">
            {{ $t('views.MyAccount.My Company') }}
          </router-link>
        </li>
        <li :class="isActive('users')">
          <router-link to="/users">
            {{ $t('views.MyAccount.Users') }}
          </router-link>
        </li>
        <li :class="isActive('change-password')">
          <router-link to="/change-password">
            {{ $t('views.MyAccount.Change Password') }}
          </router-link>
        </li>
        <li :class="isActive('account-preferences')">
          <router-link to="/account-preferences">
            {{ $t('views.MyAccount.Account Preferences') }}
          </router-link>
        </li>
        <li
          v-if="$enableWallet() && wallet.USD.isActive && $hasRole(['CLIENT_ADMIN', 'CLIENT_AGENT_SBC', 'CLIENT_FINANCE'])"
          :class="{active:$route.name==='my-wallet' && $route.params.currency==='USD'}">
          <router-link :to="{name:'my-wallet',params:{currency:'USD'}}">
            {{ $t('views.MyAccount.Wallet') }} (USD)
          </router-link>
        </li>
        <li
          v-if="$enableWallet() && wallet.ZAR.isActive && $hasRole(['CLIENT_ADMIN', 'CLIENT_AGENT_SBC', 'CLIENT_FINANCE'])"
          :class="{active:$route.name==='my-wallet' && $route.params.currency==='ZAR'}">
          <router-link :to="{name:'my-wallet',params:{currency:'ZAR'}}">
            {{ $t('views.MyAccount.Wallet') }} (ZAR)
          </router-link>
        </li>
        <li
          v-if="$hasRole(['CLIENT_ADMIN', 'CLIENT_AGENT_SB', 'CLIENT_AGENT_SBC', 'CLIENT_FINANCE'])"
          :class="isActive('credit-card')">
          <router-link to="/credit-card">
            {{ $t('views.MyAccount.Credit Card') }}
          </router-link>
        </li>
        <li
          v-if="$hasRole(['CLIENT_ADMIN'])"
          :class="isActive('my-pricing')">
          <router-link to="/my-pricing">
            Pricing
          </router-link>
        </li>
        <!--        <li><a @click.prevent="handleLogout">{{ $t('views.TheHeader.logout') }}</a></li>-->
      </ul>
    </div>
  </aside>
</template>

<script>
import { doLogout, retrieveAgent } from '@/service/auth'

export default {
  name: 'MyAccountSidebar',
  data () {
    return {
      wallet: retrieveAgent('wallet')
    }
  },
  methods: {
    isActive (routeName) {
      return {
        active: this.$route.name === routeName
      }
    },
    handleLogout () {
      doLogout()
    }
  }
}
</script>

<style scoped>

</style>
