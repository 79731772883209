<template>
  <div class="my-bookings-detail-page">
    <div class="section">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-2x">
          <div class="column is-mobile">
            <PpBreadcrumb
              :routes="breadCrumb"
              class="has-text-weight-bold search-one--breadcrumb is-inline" />
            <span class="has-text-secondary has-text-weight-bold">{{ $t('views.breadcrumb.Booking Detail') }}</span>
          </div>
          <div class="column is-mobile is-narrow">
            <router-link
              to="/my-bookings"
              class="has-text-secondary has-text-weight-bold">
              <IconBase
                width="13"
                height="13"
                icon-name="arrow left">
                <IconArrowLeft />
              </IconBase>
              {{ $t('views.MyBookings.See all bookings') }}
            </router-link>
          </div>
        </div>
        <!--breadcrumb end -->
        <h2 class="title is-size-3 has-text-primary m-b-0">
          {{ $t('views.breadcrumb.Booking Detail') }}
        </h2>
        <div class="columns is-vcentered">
          <div class="column">
            <OrderDetailCopier
              v-if="order&&property&&property.location&&booking&&contactPerson"
              :order-reference="order.orderReference"
              :property-name="property.name"
              :address="property.location.address"
              :room-count="booking.roomCount"
              :nights="booking.nights"
              :check-in="booking.checkInDate"
              :check-out="booking.checkOutDate"
              :lead-guest="contactPerson.fullName"
              :adult-count="booking.adultCount"
              :children-count="booking.childrenCount" />
          </div>
          <div class="column">
            <div
              v-if="order && order.status && order.status.code!=='ON_HOLD'"
              class="has-text-right">
              <el-button
                :disabled="disableSendMail"
                class="button-secondary button-155"
                @click.prevent="sendEmailModalActive = true">
                {{ $t('views.book.Send to Email') }}
              </el-button>
              <el-button
                :disabled="disableVoucher"
                class="button-secondary button-155"
                @click.prevent="() => downloadVoucherOrInvoice('voucher')">
                {{ $t('views.MyBookings.Get Voucher') }}
              </el-button>
              <el-button
                :disabled="disableVoucher"
                type="primary"
                class="button-155"
                @click.prevent="() => downloadVoucherOrInvoice('receipt')">
                {{ $t('views.MyBookings.Get Invoice') }}
              </el-button>
            </div>
            <div
              v-if="order && order.status && order.status.code==='ON_HOLD'"
              class="has-text-right">
              <span
                class="has-text-danger is-size-7 m-r-4x is-inline-block"
                style="max-width: 23em; vertical-align: middle;">{{ $t('pay-before',{date:payLaterDueDate}) }}</span>
              <el-button
                class="button-secondary"
                @click="$router.push({name:'bulk-payment',params:{orderReference: order.orderReference}})">
                {{ $t('Make Payment') }}
              </el-button>
            </div>
          </div>
        </div>
        <div v-if="order && order.status">
          <div class="my-bookings-detail-header">
            <OrderStatus :status="statusCode | orderStatus" />
            <div class="card-booking-ref is-size-5">
              {{ order.orderReference }}
            </div>
            <div class="card-booking-booked-by">
              {{ $t('views.MyBookings.Booked by') }} <span class="is-size-5 has-text-primary has-text-weight-bold">{{ order.createdBy }}</span>
            </div>
            <div class="card-booking-cancelled-by">
              {{ $t('views.MyBookings.Cancelled by') }} <span class="is-size-5 has-text-primary has-text-weight-bold">{{ booking.cancelledBy ? booking.cancelledBy.name : '-' }}</span>
            </div>
            <div class="booking-detail-confirmation-id">
              {{ $t('views.MyBookings.Hotel Confirmation ID') }} <span class="is-size-5 has-text-primary has-text-weight-bold">{{ booking.hotelConfirmationId ? booking.hotelConfirmationId : '-' }}</span>
            </div>
          </div>
          <!-- BOOKING DETAILS -->
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Property') }}</th>
                <th>{{ $t('views.MyBookings.Booking date') }}</th>
                <th>{{ $t('views.MyBookings.Cancelled date') }}</th>
                <th>{{ $t('views.MyBookings.Travel date') }}</th>
                <th>{{ $t('views.MyBookings.Guest Count') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    class="is-size-5 has-text-primary has-text-weight-bold"
                    style="max-width: 250px;">
                    {{ property.name }}
                  </div>
                  <div class="m-b">
                    <PropertyAddress
                      :location="property.location"
                      map-link
                      style="max-width: 250px;" />
                  </div>
                  <div class="is-size-7">
                    {{ property.contacts.phone }}
                  </div>
                </td>
                <td>
                  {{ booking.createdAt | date('MMM DD YYYY HH:mm') }}
                </td>
                <td v-if="booking.cancelledAt">
                  {{ booking.cancelledAt | date('MMM DD YYYY HH:mm') }}
                </td>
                <td v-else>
                  -
                </td>
                <td>
                  {{ booking.checkInDate|date('MMM DD YYYY') }} - {{ booking.checkOutDate|date('MMM DD YYYY') }}
                </td>
                <td>
                  <span class="is-block">{{ `${booking.adultCount} ${$t('views.CheckoutFormSummary.adults')}, ` }}</span>
                  <span class="is-block">{{ `${booking.childrenCount} ${$t('views.CheckoutFormSummary.children')}` }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- CONTACT PERSON -->
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Room') }}</th>
                <th>{{ $t('views.MyBookings.Guest Name') }}</th>
                <!--<th>{{ $t('views.MyBookings.Contact No') }}</th>
                <th>{{ $t('views.MyBookings.Email') }}</th>-->
                <th>{{ $t('views.MyBookings.Nationality') }}</th>
                <th>{{ $t('views.MyBookings.Remarks') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(guest, idx) in booking.roomLeadGuests"
                :key="idx">
                <td>
                  <div style="max-width: 250px;">
                    <span class="is-block has-text-weight-bold has-text-primary">{{ $t('views.MyBookings.Room') }} {{ idx + 1 }}</span>
                    <span class="is-block">{{ booking.supplierDescription | truncate }}</span>
                    <span
                      v-if="$isFoodCodeValid(booking.foodCode)"
                      class="is-block">
                      {{ booking.foodCode | foodCode }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ `${guest.firstName} ${guest.lastName}` }}
                </td>
                <!--<td>
              <span v-if="guest.contactNo">
                {{ `${guest.telCountryCode} ${guest.contactNo}` }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td>
                  {{ guest.email || '-' }}
                </td>-->
                <td>
                  {{ getCountryName(guest.nationality) }}
                </td>
                <td>
                  <span
                    class="is-block"
                    style="max-width: 200px;">{{ guest.remarks ||'-' }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Cancellation policy -->
          <CancellationPolicies
            v-if="booking.metadata.cancellationPolicy && booking.metadata.cancellationPolicy.cancellationPolicies.length > 0"
            :cancellation-policies="booking.metadata.cancellationPolicy.cancellationPolicies"
            :free-cancellation-before="booking.freeCancellationBefore"
            :total-amount="order.agentActualChargeAmount"
            :booking-created="booking.createdAt"
            :show-cancel-btn="showCancelBtn"
            @cancelBooking="handleCancelBooking" />

          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Price') }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ $t('views.MyBookings.Total amount') }}
                </td>
                <td class="has-text-right">
                  {{ order.initialSellRate | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel'">
                <td>
                  Markup
                </td>
                <td class="has-text-right">
                  {{ order.agentMarkupAmount | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel'">
                <td>
                  Commission
                </td>
                <td class="has-text-right">
                  {{ order.agentFinalAdjustmentAmount | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType === 'pay_at_hotel'">
                <td
                  class="has-border"
                  style="padding:0 15px !important">
                  Pay at hotel commission
                </td>
                <td class="has-border has-text-right">
                  {{ order.agentProfit | currencyObj }}
                </td>
              </tr>
              <tr v-if="payment && payment.paymentType !== 'pay_at_hotel'">
                <td class="has-border">
                  {{ $t('views.book.Transaction fee') }}
                </td>
                <td class="has-border has-text-right">
                  {{ {value: order.finalAdjustmentAmount.value + order.additionalFeeAmount.value, currency: order.finalAdjustmentAmount.currency} | currencyObj }}
                </td>
              </tr>
              <tr>
                <td class="has-border">
                  {{ $t('views.MyBookings.Total charged amount') }}
                </td>
                <td class="has-border has-text-right">
                  {{ order.agentActualChargeAmount | currencyObj }}
                </td>
              </tr>
              <tr>
                <td>
                  Commission <span
                    v-if="order.agentEarningStatus"
                    :class="'UNPAID'===order.agentEarningStatus ?'has-background-danger':'has-background-success'"
                    class="card-booking-status"> {{ order.agentEarningStatus }} </span>
                </td>
                <td class="has-text-right">
                  {{ order.agentProfit | currencyObj }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('views.MyBookings.Payment status') }}</th>
                <th>{{ $t('views.MyBookings.Payment gateway') }}</th>
                <th>{{ $t('views.MyBookings.Captured') }}</th>
                <th>{{ $t('views.MyBookings.Paid') }}</th>
                <th>{{ $t('views.MyBookings.Refunded') }}</th>
                <th v-if="order.status.code==='ON_HOLD'" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ $t(`paymentStatus.${payment.status}`) || ucFirst(payment.status) }}
                </td>
                <td>
                  {{ ucFirst(payment.paymentGateway) }}
                </td>
                <td>
                  {{ getPaidAndCapturedStatus ? $t('views.MyBookings.Yes') : $t('views.MyBookings.No') }}
                </td>
                <td>
                  {{ getPaidAndCapturedStatus ? $t('views.MyBookings.Yes') : $t('views.MyBookings.No') }}
                </td>
                <td>
                  {{ payment.status === 'refunded' ? $t('views.MyBookings.Yes') : $t('views.MyBookings.No') }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 200px;">
                  {{ $t('views.MyBookings.Agent reference code') }}
                </th>
                <th>{{ $t('views.MyBookings.Agent Remarks') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ order.metadata.agentReferenceCode || '-' }}
                </td>
                <td>
                  {{ order.metadata.agentRemarks || '-' }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <thead>
              <tr>
                <th>Transfer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ addTransferLabel }}</td>
              </tr>
            </tbody>
          </table>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('views.MyBookings.Additional Note') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <a
                  class="has-text-white"
                  @click="additionalNoteActive = true">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="edit">
                    <IconEdit />
                  </IconBase>
                  {{ $t('views.MyAccount.Edit') }}</a>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table">
            <div class="column is-6">
              <div class="td">
                <div>{{ order.metadata.additionalNote || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('views.MyBookings.RTX Admin Note') }}
              </div>
            </div>
          </div>
          <div class="columns is-gapless columns-table m-b-0">
            <div class="column is-6">
              <div class="td">
                {{ order.metadata.adminNoteExternal || '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SendConfirmationEmail
      v-if="order && order.status"
      :open.sync="sendEmailModalActive"
      :email="contactPerson?contactPerson.email:''"
      :order-reference="order?order.orderReference:''"
      :status="order?order.status.code:''"
      :super-admin="false" />

    <PpModal v-model="additionalNoteActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="additionalNoteForm"
            :model="localForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyBookings.additional-note-modal.title') }}
              </h3>
              <div>
                {{ $t('views.MyBookings.additional-note-modal.description') }}
              </div>
            </div>
            <el-form-item
              :label="$t('views.MyBookings.additional-note-modal.additional-note')"
              class="column is-12"
              prop="additionalNote">
              <el-input
                v-model="localForm.additionalNote"
                :rows="3"
                type="textarea" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="additionalNoteActive = false">
                {{ $t('views.MyBookings.Cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSubmitNote">
                {{ $t('views.MyBookings.Save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>
<script>
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'
import { mapState } from 'vuex'
import store from '@/store'
import { retrieveUser } from '@/service/auth'

import OrderStatus from '@/components/OrderStatus'
import PropertyAddress from '@/components/PropertyAddress'
import IconArrowLeft from '@/components/icons/IconArrowLeft'
import IconEdit from '@/components/icons/IconEdit'
import dates from '@/plugins/dates'
import SendConfirmationEmail from '@/views/share/SendConfirmationEmail'
import OrderDetailCopier from '@/components/OrderDetailCopier'
import CancellationPolicies from '@/components/CancellationPolicies'

const asyncData = async ({ params }) => {
  try {
    await store.dispatch('order/retrieveOrderByReference', { orderReference: params.orderReference }
    )
  } catch (err) {
    if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
    store.commit('SET_GLOBAL_MODAL', {
      show: true,
      content: err.message || (err.error && err.error.message) || ''
    })
  }
}

const requestTransferOptions = {
  ADD_AIRPORT_TRANSFER: 'Yes! Add (airport) transfer to this booking',
  ADD_TOUR_TICKET: 'Yes! Add a tour/activity or tickets to this booking',
  ONLY_ACCOMMODATION: 'No, I only need the accommodation'
}

export default {
  name: 'MyBookingsDetail',
  components: {
    OrderDetailCopier,
    SendConfirmationEmail,
    IconArrowLeft,
    IconEdit,
    PropertyAddress,
    OrderStatus,
    CancellationPolicies
  },
  async beforeRouteEnter (to, from, next) {
    try {
      if (!to.params.orderReference) {
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: 'Booking not Found'
        })
      }
      // await asyncData({ params: to.params })
      store.commit('order/CLEAR_ORDER_DETAILS')
      next()
    } catch (err) {
      next()
    }
  },
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'Make Payment': 'Make Payment',
        'pay-before': 'Please pay before {date} to confirm this booking.'
      }
    }
  },
  data () {
    return {
      additionalNoteActive: false,
      sendEmailModalActive: false,
      localForm: {
        additionalNote: undefined
      },
      rules: {
        additionalNote: {
          max: 500,
          trigger: [
            'change',
            'blur'
          ]
        }
      }
    }
  },
  computed: {
    ...mapState('order', {
      agent: state => state.orderDetails.agent,
      booking: state => state.orderDetails.booking,
      property: state => state.orderDetails.property,
      order: state => state.orderDetails.order,
      contactPerson: state => state.orderDetails.contactPerson || state.orderDetails.order.contactPerson,
      payment: state => state.orderDetails.payment,
      isFetching: state => state.isFetching,
      isContinuePulling: state => state.orderDetails.isContinuePulling
    }),
    breadCrumb () {
      return [
        {
          label: this.$t('views.breadcrumb.Manage Bookings'),
          replace: true,
          route: { name: 'my-bookings' }
        }
      ]
    },
    showCancelBtn () {
      if (!this.booking) return false
      return ['CONFIRMED', 'ON_HOLD'].includes(this.statusCode) &&
        dates.dateDiff(new Date().toISOString(), this.booking.checkInDate) > -1 &&
        (this.$hasRole(['CLIENT_ADMIN', 'CLIENT_AGENT_SBC', 'CLIENT_CS', 'CLIENT_FINANCE']) ||
        (this.$hasRole('CLIENT_AGENT_SB') && this.order.createdByUid === retrieveUser('uid')))
    },
    cancellationPolicies () {
      if (!this.booking) return []
      if (this.booking && !Object.keys(this.booking.metadata).length) return []
      else if (this.booking && Object.keys(this.booking.metadata).length && this.booking.freeCancellationBefore) {
        const cxp = JSON.parse(JSON.stringify(this.booking.metadata.cancellationPolicy.cancellationPolicies))
        let tmp = cxp.filter(p => [0, 100].includes(p.penalty_percentage))
        // sometime 0% band missing
        if (!tmp.find(p => p.penalty_percentage === 0)) {
          tmp.unshift({
            penalty_percentage: 0,
            date_from: this.booking.createdAt,
            date_to: dates.add(this.booking.freeCancellationBefore, this.$store.state.cancellationBuffer)
          })
        }
        tmp = tmp.map((p) => {
          if (p.penalty_percentage === 100) {
            p.date_from = dates.add(this.booking.freeCancellationBefore || this.booking.createdAt, this.$store.state.cancellationBuffer)
          }
          return p
        })
        return tmp
      } else if (this.booking && Object.keys(this.booking.metadata).length && !this.booking.freeCancellationBefore) {
        return this.booking.metadata.cancellationPolicy.cancellationPolicies
      } else {
        return []
      }
    },
    payLaterDueDate () {
      const date = this.payment && this.payment.metadata && ('payLaterDueDate' in this.payment.metadata) ? this.payment.metadata.payLaterDueDate : ''
      return `${this.$options.filters.dateTz(date, { tz: 'Africa/Johannesburg', isBeforeMidnight: false })} SAST`
    },
    statusCode () {
      return this.order.status && this.order.status.code
    },
    disableVoucher () {
      return this.statusCode !== 'AWAITING_CONFIRMATION' && this.statusCode !== 'CONFIRMED'
    },
    disableSendMail () {
      return this.statusCode !== 'AWAITING_CONFIRMATION' && this.statusCode !== 'CONFIRMED' && this.statusCode !== 'CANCELLED'
    },
    addTransferLabel () {
      let label = ''
      if (this.order.metadata && this.order.metadata.requestTransfer && this.order.metadata.requestTransfer.length > 0) {
        const onlyAccommodationExists = this.order.metadata.requestTransfer.find(e => e === 'ONLY_ACCOMMODATION')
        if (onlyAccommodationExists) {
          label = requestTransferOptions[this.order.metadata.requestTransfer[0]]
        } else {
          if (this.order.metadata.requestTransfer.length > 0) {
            this.order.metadata.requestTransfer.forEach((key, idx) => {
              label += `${requestTransferOptions[key]}`
              label += idx === this.order.metadata.requestTransfer.length - 1 ? '' : ', '
            })
          }
        }
      }
      return label || '-'
    },
    getPaidAndCapturedStatus () {
      if (this.payment && this.payment.paymentGateway === 'pay_at_hotel') {
        var today = new Date()
        today.setHours(0, 0, 0, 0)
        const checkoutDate = new Date(this.booking.checkOutDate)
        return today >= checkoutDate && this.order.status.code === 'CONFIRMED'
      } else {
        return this.payment.status === 'succeeded'
      }
    }
  },
  async created () {
    this.$store.commit('SHOW_FULLSCREEN_LOADER')
    await asyncData({ params: this.$route.params })
    this.$store.commit('HIDE_FULLSCREEN_LOADER')
  },
  methods: {
    countDays (dayFrom, dayTo) {
      const diff = dates.dateDiff(dayFrom, dayTo)
      return diff > -1 ? diff : '-'
    },
    getCountryName (code) {
      return getCountryByAlpha2(code).lb
    },
    downloadVoucherOrInvoice (type) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      store.dispatch('order/getVoucherOrInvoice', {
        orderReference: this.order.orderReference,
        type,
        superAdmin: false
      }).then(({ data, headers }) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        const newBlob = new Blob([data], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          // this.handleDownloadModalClosed()
          return
        }
        const tmp = window.URL.createObjectURL(newBlob)
        const contentDisposition = headers['content-disposition']
        const filename = contentDisposition.replace('attachment; filename=', '')
        let link = document.createElement('a')
        link.href = tmp
        link.download = filename
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(tmp)
        }, 100)
      }).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || this.$t('app.subtitle'),
          okBtn: true
        })
      })
    },
    handleCancelBooking () {
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      store.dispatch('order/cancelOrder', { orderReference: this.order.orderReference, superAdmin: false }).then(() => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$alert(this.$t('views.MyBookings.success-message'), this.$t('views.MyBookings.success-title'), {
          confirmButtonClass: 'button-secondary button-100',
          confirmButtonText: this.$t('app.ok'),
          customClass: 'success-message-box'
        }).then(() => {
          this.$store.commit('SHOW_FULLSCREEN_LOADER')
          const cancelInterval = setInterval(async () => {
            await asyncData({ params: this.params })
            if (['REFUND_IN_PROGRESS', 'CANCELLED'].indexOf(this.statusCode) > -1) {
              clearInterval(cancelInterval)
              this.$store.commit('HIDE_FULLSCREEN_LOADER')
              if (this.statusCode === 'REFUND_IN_PROGRESS') {
                store.commit('SET_GLOBAL_MODAL', {
                  show: true,
                  okBtn: true,
                  title: this.$t('views.MyBookings.error.refundInProgress.title'),
                  content: this.$t('views.MyBookings.error.refundInProgress.content')
                })
              }
            }
          }, 6000) // 3 seconds is not enough. Wait 6 seconds
        })
      }).catch(async (err) => {
        await asyncData({ params: this.$route.params })
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          okBtn: true,
          title: this.$t('views.MyBookings.error.cancelInProgress.title'),
          content: this.$t('views.MyBookings.error.cancelInProgress.content')
        })
      }).catch(async (err) => {
        await asyncData({ params: this.$route.params })
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          okBtn: true,
          title: this.$t('views.MyBookings.error.cancelInProgress.title'),
          content: this.$t('views.MyBookings.error.cancelInProgress.content')
        })
      })
    },
    ucFirst (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async handleSubmitNote () {
      try {
        this.$refs.additionalNoteForm.validate(async (valid) => {
          if (valid) {
            this.additionalNoteActive = false
            this.$store.commit('SHOW_FULLSCREEN_LOADER')
            await this.$store.dispatch('order/editMetadata', {
              additionalNote: this.localForm.additionalNote,
              orderReference: this.order.orderReference
            })
            await asyncData({ params: this.$route.params })
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.localForm.additionalNote = undefined
          }
        })
      } catch (err) {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: this.$t(`error.${err.error && err.error.code}`) || err.message || (err.error && err.error.message) || ''
        })
      }
    }
  },
  metaInfo () {
    return {
      title: 'Booking Details'
    }
  }
}
</script>
<style lang="scss" scoped>
  .my-bookings-detail-header {
    display: flex;
    margin-top: 35px;
    margin-bottom: 20px;
    align-items: flex-start;
    .booking-detail-confirmation-id {
      max-width: 12em;
    }
  }

  .card-booking-booked-by {
    margin-right: 45px;
    margin-left: auto;
  }

  .card-booking-cancelled-by {
    margin-right: 45px;
  }
</style>
