<template>
  <el-form
    ref="searchBookingsForm"
    :model="localForm"
    :rules="rules"
    class="columns is-multiline search-bookings-form"
    label-position="top">
    <div class="column is-12">
      <h2 class="is-size-3 has-text-weight-bold has-text-white">
        {{ $t('title') }}
      </h2>
    </div>
    <div class="column is-6">
      <div class="columns">
        <el-form-item
          :label="$t('views.MyBookings.form.dateRange.label')"
          class="column is-4 hide-border-radius-right"
          prop="dateRangeType">
          <el-select
            v-model="localForm.dateRangeType"
            :placeholder="$t('views.MyBookings.form.dateRange.placeholder')"
            @change="handleSearch">
            <el-option
              v-for="item in dateRageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item
          class="column is-8 hide-border-radius-left"
          label=" "
          prop="dateRange">
          <DatePicker
            v-model="localForm.dateRange"
            :hovering-tooltip="false"
            :min-nights="0"
            :show-year="true"
            :display-clear-button="true"
            @input="handleSearch" />
        </el-form-item>
      </div>
    </div>
    <el-form-item
      :label="$t('views.MyBookings.form.orderReference.label')"
      class="column is-2"
      prop="orderReference">
      <el-input
        v-model="localForm.orderReference"
        :placeholder="$t('views.MyBookings.form.orderReference.placeholder')"
        @input="delaySearch" />
      <template #error="{ error }">
        <div class="el-form-item__error">
          <span style="background-color: #FFE8E8">{{ error }}</span>
        </div>
      </template>
    </el-form-item>
    <el-form-item
      :label="$t('views.MyBookings.form.agentName.placeholder')"
      class="column is-2"
      prop="users">
      <el-select
        v-model="localForm.users"
        :placeholder="$t('views.MyBookings.form.agentName.placeholder')"
        filterable
        multiple
        collapse-tags
        @change="delaySearch">
        <el-option
          v-for="item in agentOptions"
          :key="item.uid"
          :label="item.displayName"
          :value="item.uid" />
      </el-select>
    </el-form-item>
    <el-form-item
      :label="$t('views.MyBookings.form.paymentCurrency.placeholder')"
      class="column is-2"
      prop="paymentCurrency">
      <InputCurrency
        v-model="localForm.paymentCurrency"
        @change="delaySearch" />
    </el-form-item>
    <div class="column is-12 has-text-right">
      <div style="height: 19px;" />
      <el-button
        v-if="hasSearchParams"
        class="button-white-outline"
        @click="clear">
        {{ $t('components.FiltersPriceRange.clear') }}
      </el-button>
    </div>
  </el-form>
</template>

<script>
import DatePicker from '@/components/DatePicker/index'
import debounce from 'lodash/debounce'
import InputCurrency from '../../components/InputCurrency'
import { retrieveAgent } from '@//service/auth'
import moment from 'moment'

export default {
  name: 'SettlePaymentForm',
  components: { InputCurrency, DatePicker },
  i18n: {
    messages: {
      'en-US': {
        'title': 'Settle Payment',
        'payment-deadline': 'Payment deadline'
      }
    }
  },
  data () {
    return {
      localForm: {
        dateRangeType: 'payment-deadline',
        paymentCurrency: retrieveAgent('defaultCurrency'),
        dateRange: {
          checkInDate: '',
          checkOutDate: ''
        },
        orderReference: '',
        // hotelName: '',
        // guestName: '',
        users: []
        // orderStatuses: [],
        // agentReference: ''
      },
      agentOptions: [],
      dateRageOptions: [
        {
          value: 'payment-deadline',
          label: this.$t('payment-deadline')
        }
      ],
      rules: {
        orderReference: [
          { type: 'string', min: 4, message: this.$t('views.MyBookings.form.orderReference.invalid'), trigger: [ 'change', 'input' ] },
          {
            pattern: /^[a-zA-Z0-9]*$/,
            message: this.$t('views.MyBookings.form.orderReference.regex'),
            trigger: ['change', 'blur']
          }
        ]
      }
    }
  },
  computed: {
    hasSearchParams () {
      return this.localForm.dateRange.checkOutDate || this.localForm.dateRange.checkInDate || this.localForm.orderReference || this.localForm.users.length
    }
  },
  created () {
    this.$store.dispatch('user/fetchUsers').then(
      ({ outlets, meta }) => {
        this.agentOptions = outlets.items
        this.$store.commit('order/SET_AGENT_OPTIONS', outlets.items)
      })
    const query = this.$route.query
    if (query.paymentCurrency) {
      this.localForm.paymentCurrency = query.paymentCurrency
    }
    if (query.orderReference) {
      this.localForm.orderReference = query.orderReference
    }
    if (query.users) {
      this.localForm.users = query.users.split(',')
    }
    if (query.paymentDeadlineDateRange) {
      const range = query.paymentDeadlineDateRange.split('|')
      this.localForm.dateRange.checkInDate = range[0]
      this.localForm.dateRange.checkOutDate = range[1]
    }
  },
  methods: {
    clear () {
      this.localForm.dateRangeType = 'payment-deadline'
      this.localForm.dateRange = {
        checkInDate: '',
        checkOutDate: ''
      }
      this.localForm.orderReference = ''
      this.localForm.users = []
      this.localForm.paymentCurrency = retrieveAgent('defaultCurrency')

      this.$router.replace({
        name: 'settle-payment'
      })
      this.$emit('submitted')
    },
    handleSearch () {
      this.$refs.searchBookingsForm.validate().then((valid) => {
        if (valid) {
          const query = {}
          if (this.localForm.dateRange.checkInDate) {
            const start = moment(this.localForm.dateRange.checkInDate).format('YYYY-MM-DD')
            const end = moment(this.localForm.dateRange.checkOutDate).format('YYYY-MM-DD')
            query.paymentDeadlineDateRange = `${start}|${end}`
          }
          if (this.localForm.orderReference) {
            query.orderReference = this.localForm.orderReference
          }
          if (this.localForm.users.length) {
            query.users = this.localForm.users.join(',')
          }
          if (this.localForm.paymentCurrency) {
            query.paymentCurrency = this.localForm.paymentCurrency
          }
          this.$router.replace({
            name: 'settle-payment',
            query
          })
          this.$emit('submitted')
        }
      })
    },
    delaySearch: debounce(function () {
      this.handleSearch()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
  .search-bookings-form {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -10px;
    margin-bottom: -10px;

    .columns {
      margin-left: -8px;
      margin-right: -8px;
      margin-top: -10px;

      &:last-child {
        margin-bottom: -10px;
      }
    }

    .column {
      padding: 10px 8px;
    }

    .el-select {
      width: 100%;
    }
  }
</style>
