<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div class="main-content-inner">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary m-b-4x">
            {{ $t('views.MyAccount.Change Password') }}
          </h2>
          <div style="max-width: 320px">
            <el-form
              ref="passwordForm"
              :model="passwordForm"
              :rules="rules"
              class="columns is-multiline"
              label-position="top"
              status-icon>
              <el-form-item
                class="column is-12"
                label="Password"
                prop="oldPassword">
                <template #label>
                  {{ $t('views.customer.form.old-password.label') }}
                  <a
                    class="toggle-password has-text-secondary is-pulled-right"
                    @click="togglePassword">{{ togglePasswordText }}</a>
                </template>
                <el-input
                  v-model.trim="passwordForm.oldPassword"
                  :type="passwordType"
                  autocomplete="off" />
              </el-form-item>
              <el-form-item
                :label="$t('views.customer.form.new-password.label')"
                class="column is-12"
                prop="password">
                <el-input
                  v-model.trim="passwordForm.password"
                  :type="passwordType"
                  autocomplete="off"
                  @input="handleValidRetype" />
              </el-form-item>
              <el-form-item
                :label="$t('views.customer.form.retype-new-password.label')"
                class="column is-12"
                prop="retypePassword">
                <el-input
                  v-model.trim="passwordForm.retypePassword"
                  :type="passwordType"
                  autocomplete="off" />
              </el-form-item>
              <div class="column is-12 has-text-right">
                <el-button
                  :loading="isSubmitting"
                  class="button-secondary"
                  style="min-width: 155px;"
                  @click="handleSave">
                  {{ $t('views.MyAccount.Change Password') }}
                </el-button>
              </div>
            </el-form>
          </div>
        </div>

        <PpModal v-model="successModal">
          <div class="box">
            <div class="box-content">
              <h5 class="title is-5 has-text-secondary">
                {{ $t('views.MyAccount.Change Password') }}
              </h5>
              <PpGap />
              <p class="is-size-7">
                {{ $t('views.customer.ChangePassword.successfullyChanged') }}
              </p>
              <PpGap size="large" />
              <div class="has-text-right">
                <el-button
                  class="button-secondary"
                  size="small"
                  style="min-width: 155px;"
                  @click="successModal = false">
                  {{ $t('app.ok') }}
                </el-button>
              </div>
            </div>
          </div>
        </PpModal>
      </main>
    </div>
  </div>
</template>

<script>
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'
import mixPassword from './mixins/password'
export default {
  name: 'ChangePassword',
  components: {
    MyAccountSidebar
  },
  mixins: [mixPassword],
  data () {
    return {
      isSubmitting: false,
      successModal: false,
      passwordForm: {
        oldPassword: undefined,
        password: undefined,
        retypePassword: undefined
      },
      rules: {
        oldPassword: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change',
              'blur'
            ]
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
            message: this.$t('views.customer.form.password.regex'),
            trigger: ['change', 'blur']
          }
        ],
        retypePassword: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.passwordForm.password) {
                callback(new Error(this.$t('views.customer.form.retype-password.invalid')))
              } else {
                callback()
              }
            },
            trigger: [
              'change',
              'blur'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.retype-password.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleSave () {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.isSubmitting = true
          this.$store.commit('SHOW_FULLSCREEN_LOADER', 'saving')
          const payload = {
            oldPassword: this.passwordForm.oldPassword,
            password: this.passwordForm.password
          }
          this.$store.dispatch('user/changePassword', payload).then(
            ({ outlets, meta }) => {
              this.$store.commit('meta/SET_META_ACTION', meta)
              if (outlets.ok) {
                this.successModal = true
              }
              this.isSubmitting = false
              this.$store.commit('HIDE_FULLSCREEN_LOADER')
              this.$refs.passwordForm.resetFields()
            }
          ).catch((err) => {
            this.isSubmitting = false
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.$store.dispatch('setGlobalErrorModal', {
              show: true,
              content: err.message || this.$t(`error.changePassword.${err.error.code}`) || (err.error && err.error.message) || ''
            })
          })
        }
      })
    },
    handleValidRetype () {
      if (this.passwordForm.retypePassword !== undefined) {
        this.$refs.passwordForm.validateField('retypePassword')
      }
    }
  },
  metaInfo () {
    return {
      title: 'Change Password'
    }
  }
}
</script>

<style scoped>

</style>
