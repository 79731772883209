<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div class="main-content-inner">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary">
            {{ $t('views.MyAccount.Wallet') }} ({{ walletCurrency }})
          </h2>
          <div class="columns">
            <div class="column">
              <div class="is-size-5">
                <b class="has-text-primary">{{ $t('views.MyAccount.Current balance') }}: </b> <span v-if="availableBalance">{{ availableBalance|currencyObj }}</span>
              </div>
              <div
                v-if="minBalance && minBalance.value<0"
                class="is-size-5">
                {{ $t('views.MyAccount.wallet-info') }} {{ minBalance|currencyObj }}.
              </div>
              <div
                v-if="$hasRole(['CLIENT_ADMIN'])"
                style="margin-top: 15px;">
                <el-checkbox
                  v-model="emailMeDaily"
                  @change="emailMeChange">
                  {{ $t('views.MyAccount.emailMeDaily') }}
                </el-checkbox>
              </div>
            </div>
            <div class="column is-narrow  has-text-right-tablet">
              <el-button
                v-if="$hasRole(['CLIENT_ADMIN', 'CLIENT_FINANCE'])"
                class="button-secondary"
                style="min-width: 140px;"
                @click="topUpModalActive=true">
                {{ $t('views.MyAccount.Request Top Up') }}
              </el-button>
              <p class="m-t">
                <a
                  class="has-text-success"
                  @click.prevent="tipsModalActive=true">{{ $t('views.MyAccount.topUpTips.title') }}?</a>
              </p>
            </div>
          </div>
          <hr style="margin: 0 0 25px 0;">
          <div class="columns">
            <div class="column">
              <DateRangFilterForm @searched="handleFilterApply" />
            </div>
            <div class="column is-narrow">
              <el-button
                class="button-secondary"
                style="min-width: 140px;"
                @click="handleExport">
                {{ $t('views.MyAccount.Export to Excel') }}
              </el-button>
            </div>
          </div>
          <div
            v-if="isFetching"
            class="column has-text-center m-t-2x">
            <PpGap size="large" />
            <PpSpiner />
          </div>
          <h2
            v-if="!items.length && !isFetching"
            class="has-text-centered is-size-5">
            {{ $t('components.StaticDataAutosuggest.query.no-data-text') }}
          </h2>
          <el-table
            v-if="!isFetching && items.length"
            ref="singleTable"
            :data="items"
            stripe
            style="width: 100%">
            <el-table-column
              :label="$t('views.MyAccount.Timestamp')"
              width="170">
              <template slot-scope="scope">
                {{ formatDate(scope.row.createdAt) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('views.MyBookings.User')">
              <template slot-scope="scope">
                {{ scope.row.displayName }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('views.MyAccount.Action')">
              <template slot-scope="scope">
                {{ $t(`views.MyAccount.action.${scope.row.action}`) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('views.MyAccount.Amount')"
              align="right">
              <template slot-scope="scope">
                <span :class="`${scope.row.type === 'DB' ?'has-text-danger':'has-text-success'}`">
                  {{ formatCcy(scope.row) | number }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('views.MyAccount.Balance')">
              <template slot-scope="scope">
                {{ scope.row.endingBalance / 100 | number }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('views.MyAccount.Currency')"
              prop="currency"
              width="85" />>
            <el-table-column
              :label="$t('views.MyAccount.Remarks')"
              width="150">
              <template slot-scope="scope">
                <div
                  :title="scope.row.metadata.remark"
                  class="has-text-ellipsis">
                  {{ scope.row.metadata.remark }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="column is-12-mobile has-text-centered-mobile has-text-right">
            <el-pagination
              v-if="totalItems > 10"
              :pager-count="5"
              :page-size="10"
              :current-page="query.page"
              :total="totalItems"
              class="pagi"
              background
              layout="prev, pager, next"
              @current-change="handlePageChange" />
          </div>

          <!-- Topup modal -->
          <PpModal v-model="topUpModalActive">
            <PpCard class="box has-border is-flex">
              <div class="edit-profile-modal-content modal-columns-outer">
                <el-form
                  ref="topUpForm"
                  :model="topUpForm"
                  :rules="rules"
                  class="columns is-multiline"
                  label-position="top"
                  status-icon>
                  <div class="column is-12">
                    <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                      {{ $t('views.MyAccount.Request top up') }}
                    </h3>
                  </div>
                  <div
                    v-if="errMsg"
                    class="error-box m-t-3x">
                    <el-alert
                      :title="errMsg"
                      :closable="false"
                      type="error" />
                  </div>
                  <el-form-item
                    :label="$t('views.customer.form.account-name.label')"
                    class="column is-12"
                    prop="accountName">
                    <el-input
                      v-model="topUpForm.accountName"
                      :placeholder="$t('views.customer.form.account-name.placeholder')"
                      autocomplete="accountName" />
                  </el-form-item>
                  <el-form-item
                    :label="$t('views.customer.form.transferred-amount.label')"
                    class="column is-12"
                    prop="amount">
                    <div class="el-input-number-wrapper">
                      <!-- <el-input
                        v-model="topUpForm.amount"
                        type="text"
                        placeholder="Amount"
                        style="width: 100%"
                        @input="toCurrency" /> -->
                      <money-input v-model="topUpForm.amount" />
                      <span
                        class="hack-input-number-suffix has-text-weight-bold"
                        style="right: 30px">{{ walletCurrency }}</span>
                    </div>
                  </el-form-item>
                  <el-form-item
                    :label="$t('views.customer.form.bank-transfer-slip.label')"
                    class="column is-12"
                    prop="transferSlip">
                    <el-input
                      v-model="topUpForm.transferSlip"
                      type="hidden"
                      style="display: none" />
                    <el-upload
                      v-show="!transferSlip"
                      ref="upload"
                      :show-file-list="false"
                      :multiple="false"
                      :auto-upload="false"
                      :http-request="uploadTransferSlip"
                      :on-change="onTransferSlipChange"
                      accept="image/jpeg,image/png,application/pdf"
                      action="#">
                      <el-button
                        size="small"
                        type="primary">
                        {{ $t('views.customer.form.bank-transfer-slip.placeholder') }}
                      </el-button>
                    </el-upload>
                    <span>{{ $t('views.customer.form.bank-transfer-slip.rule') }}</span>
                    <div v-if="transferSlip">
                      <span class="has-text-grey-darker m-r-4x">{{ transferSlip.name }}</span>
                      <el-link
                        type="danger"
                        @click="handleRemoveFile">
                        {{ $t('views.MyAccount.remove') }}
                      </el-link>
                    </div>
                  </el-form-item>
                  <div class="column is-12">
                    {{ $t('wallet.note') }}
                  </div>
                  <div class="column is-12 has-text-right">
                    <el-button
                      class="button-secondary-outline"
                      style="min-width: 115px;"
                      @click="handleCancel">
                      {{ $t('views.customer.form.cancel') }}
                    </el-button>
                    <el-button
                      class="button-secondary"
                      style="min-width: 170px;"
                      @click="handleSendRequest">
                      {{ $t('views.customer.form.Send Request') }}
                    </el-button>
                  </div>
                </el-form>
              </div>
            </PpCard>
          </PpModal>
          <!-- Confirm modal -->
          <PpModal
            v-if="transferSlip"
            v-model="confirmTopUpModalActive">
            <PpCard class="box has-border is-flex">
              <div class="edit-profile-modal-content modal-columns-outer">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                      {{ $t('views.MyAccount.Request top up') }}
                    </h3>
                  </div>

                  <div class="column is-12">
                    <p class="m-b">
                      {{ $t('views.MyAccount.TopUp.title') }}
                    </p>
                    <p class="m-b">
                      <b class="has-text-primary">{{ $t('views.MyAccount.TopUp.Account Name') }}</b> {{ topUpForm.accountName }}
                    </p>
                    <p class="m-b">
                      <b class="has-text-primary">{{ $t('views.MyAccount.TopUp.Transferred Amount') }}</b> {{ topUpForm.amount | currency({currency:walletCurrency}) }}
                    </p>
                    <p class="m-b">
                      <b class="has-text-primary">{{ $t('views.MyAccount.TopUp.Bank transfer slip') }}</b> {{ `${transferSlip.name}(${transferSlip.size / 1e3} KB)` }}
                    </p>
                  </div>
                  <div class="column is-12">
                    {{ $t('views.MyAccount.TopUp.desc') }}
                  </div>
                  <div class="column is-12 has-text-right">
                    <el-button
                      :loading="isPosting"
                      class="button-secondary"
                      style="min-width: 170px;"
                      @click="handleSave">
                      {{ $t('views.MyAccount.TopUp.OK') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </PpCard>
          </PpModal>
          <!-- Tips  modal -->
          <PpModal v-model="tipsModalActive">
            <PpCard class="box has-border is-flex">
              <div class="edit-profile-modal-content modal-columns-outer">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                      {{ $t('views.MyAccount.topUpTips.title') }}
                    </h3>
                  </div>
                  <div class="column is-12">
                    <p>Please send funds to our bank account below, and please notify us by clicking on the <strong>request top up</strong> button after you have sent your funds. Also note that VIVA CONNECT (PTY) LTD is the name of our parent company.</p>
                    <br>
                    <BankInfo :wallet-currency="walletCurrency" />
                  </div>
                  <div class="column is-12 has-text-right">
                    <el-button
                      class="button-secondary"
                      style="min-width: 170px;"
                      @click="tipsModalActive=false">
                      {{ $t('app.ok') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </PpCard>
          </PpModal>

          <PpModal v-model="successModalActive">
            <PpCard class="box has-border is-flex">
              <div class="edit-profile-modal-content modal-columns-outer">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                      {{ $t('views.MyAccount.topUpTips.success-modal.title') }}
                    </h3>
                  </div>
                  <div class="column is-12">
                    {{ $t('views.MyAccount.topUpTips.success-modal.message') }}
                  </div>
                  <div class="column is-12 has-text-right">
                    <el-button
                      class="button-secondary"
                      @click="successModalActive=false">
                      {{ $t('app.ok') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </PpCard>
          </PpModal>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { retrieveAgent } from '@/service/auth'
import moment from 'moment-timezone'
import dates from '@/plugins/dates'
import MyAccountSidebar from '@/views/customer/MyAccountSidebar'
import DateRangFilterForm from '@/views/customer/DateRangFilterForm'
import MoneyInput from '@/components/MoneyInput'
import { download } from '@/plugins/util'
import { mapState } from 'vuex'
import BankInfo from '../../components/BankInfo'

export default {
  name: 'MyWallet',
  components: {
    BankInfo,
    DateRangFilterForm,
    MoneyInput,
    MyAccountSidebar
  },
  beforeRouteUpdate (to, from, next) {
    this.walletCurrency = to.params.currency
    this.query.currency = to.params.currency
    this.initWallet()
    next()
  },
  data () {
    return {
      successModalActive: false,
      tipsModalActive: false,
      query: {
        page: 1,
        action: undefined,
        date: undefined,
        type: 'wallet',
        currency: undefined
      },
      emailMeDaily: retrieveAgent('email').dailyBalanceReminder,
      walletCurrency: undefined,
      isFetching: false,
      isPosting: false,
      uploadEle: undefined,
      totalItems: 0,
      items: [],
      topUpModalActive: false,
      confirmTopUpModalActive: false,
      topUpForm: {
        accountName: undefined,
        amount: undefined,
        transferSlip: undefined
      },
      transferSlip: undefined,
      errMsg: undefined,
      rules: {
        accountName: [
          {
            required: true,
            message: this.$t('views.customer.form.account-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('views.customer.form.transferred-amount.required'),
            trigger: [
              'blur'
            ]
          }
        ],
        transferSlip: [
          {
            required: true,
            message: this.$t('views.customer.form.bank-transfer-slip.required'),
            trigger: [
              'change', 'submit'
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('app', {
      isSubmitting: state => state.isSubmitting,
      walletInfo: state => state.walletInfo
    }),
    availableBalance () {
      return this.walletInfo.availableBalance ? this.walletInfo.availableBalance : null
    },
    minBalance () {
      return this.walletInfo.minBalance ? this.walletInfo.minBalance : null
    }
  },
  created () {
    this.walletCurrency = this.$route.params.currency
    this.query.currency = this.$route.params.currency
    this.initWallet()
  },
  methods: {
    async initWallet () {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER')
        const payload = {
          currency: this.walletCurrency
        }
        await this.fetchData(this.query)
        await this.$store.dispatch('app/getWalletInfo', payload)
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
      } catch (err) {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      }
    },
    formatCcy (item) {
      if (!item) return 'N/A'

      let amount = item.amount
      if (item.orderReference) {
        amount = 0 - amount
      }
      return `${item.type === 'DB' ? '-' : '+'}${parseFloat(Number(amount) / 100).toFixed(2)}`
    },
    formatDate (date) {
      return moment(date).tz(retrieveAgent('timezone')).format('MMM DD, YYYY HH:mm')
    },
    emailMeChange (val) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER', 'saving')
      this.$store.dispatch('app/updateDailyBalanceReminder', { enabled: val })
        .then(({ outlets }) => {
          this.$store.commit('app/SET_APP', outlets.details)
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
        })
    },
    async fetchData (query) {
      try {
        this.isFetching = true
        const { outlets } = await this.$store.dispatch('app/fetchTransactions', query)

        this.items = outlets.items
        this.totalItems = outlets.totalItems
        this.isFetching = false
      } catch (err) {

      }
    },
    handleSendRequest () {
      this.$refs.topUpForm.validate(async (valid) => {
        if (valid) {
          this.uploadEle = this.$refs.upload
          this.topUpModalActive = false
          this.confirmTopUpModalActive = true
        }
      })
    },
    handleSave () {
      this.errMsg = undefined
      this.isPosting = true
      const options = {
        file: this.transferSlip.raw,
        filename: this.transferSlip.name
      }
      this.uploadTransferSlip(options)
    },
    handleUploadSuccess (file) {
      this.topUpForm.transferSlip = file.name
      // submit topup request
      this.$store.dispatch('app/topUpRequest', { ...this.topUpForm, currency: this.walletCurrency }).then(
        ({ outlets, meta }) => {
          if (outlets.ok) {
            this.isPosting = false
            this.confirmTopUpModalActive = false
            this.topUpForm.accountName = undefined
            this.topUpForm.amount = undefined
            this.topUpForm.transferSlip = undefined
            this.transferSlip = undefined
            this.successModalActive = true
          }
        }
      ).catch((err) => {
        this.errMsg = err.message || (err.error && err.error.message) || ''
        this.isPosting = false
      })
    },
    handleCancel () {
      this.$refs.topUpForm.resetFields()
      this.topUpModalActive = false
    },
    onTransferSlipChange (file) {
      const isValidType = ['image/jpeg', 'image/png', 'application/pdf'].includes(file.raw.type)
      if (!isValidType) {
        this.$message.error('You can only upload jpg/png/pdf file')
        return
      }
      // max file size is 1MB
      const isLessThan1mb = file.size / 1024 / 1024 < 1
      if (!isLessThan1mb) {
        this.$message.error('Transfer slip size can not exceed 1MB')
        return
      }
      if (isValidType && isLessThan1mb) {
        this.transferSlip = file
        this.topUpForm.transferSlip = file.name
      }
    },
    uploadTransferSlip (options) {
      this.uploading = true

      this.$store.dispatch('app/uploadTransferSlip', options).then((response) => {
        const results = response.outlets.results
        if (results.length > 0) {
          this.handleUploadSuccess(results[0])
        }
      }).catch((error) => {
        this.handleUploadError(error)
      }).finally(() => {
        this.uploading = false
      })
    },
    handleUploadError (err) {
      this.$message.error(err.message || (err.error && err.error.message) || '')
    },
    handleRemoveFile () {
      this.topUpForm.transferSlip = undefined
      this.transferSlip = undefined
      this.$refs.upload.clearFiles()
    },
    handlePageChange (pg) {
      this.query.page = pg
      this.fetchData(this.query)
    },
    handleFilterApply (val) {
      this.query.action = val.action || undefined
      if (val.dateRange.checkInDate && val.dateRange.checkOutDate) {
        const serializedDate = dates.fullDayRange([val.dateRange.checkInDate, val.dateRange.checkOutDate]).map(e => e.toJSON()).join('|')
        this.query.date = serializedDate !== '|' ? serializedDate : undefined
      } else {
        this.query.date = undefined
      }
      this.fetchData(this.query)
    },
    handleExport () {
      this.isLoading = true
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('app/exportTransaction', this.query)
        .then(({ data, headers }) => {
          this.isLoading = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          download(data, headers)
        }).catch((err) => {
          this.isLoading = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          this.$store.commit('SET_GLOBAL_MODAL', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        })
    }
  },
  metaInfo () {
    return {
      title: 'Wallet'
    }
  }
}
</script>
