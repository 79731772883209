<template>
  <div class="my-account-page">
    <div class="section-has-sidebar">
      <MyAccountSidebar />
      <main class="main-content">
        <div
          class="main-content-inner"
          style="max-width: 950px">
          <h2 class="has-text-weight-bold is-size-3 has-text-primary">
            Pricing
          </h2>
          <p class="m-b-2x">
            By default eBEDS will always give you a Net rate. Leave below options blank if you only want to use Net rates.
            To add a markup/commission you have two options:
          </p>
          <el-form
            ref="form"
            :model="form"
            label-position="top"
            @submit.prevent="">
            <p class="m-b-2x">
              <strong>Option 1:</strong> Gross rates - Set a <strong>default markup on all rates</strong> you see, quote, confirm and pay.
              You will earn the same % on <strong>all bookings</strong>.
            </p>
            <el-form-item
              label="Markup"
              prop="markup"
              style="max-width: 220px">
              <el-input
                v-model="form.markup"
                v-number-input.float="{min:0,decimal:2}"
                placeholder="Markup amount">
                <template slot="suffix">
                  %
                </template>
              </el-input>
            </el-form-item>
            <p class="m-b-2x">
              <strong>Option 2:</strong> Variable rates – See Net rates but add a commission <strong>per booking</strong> (on the check-out page). You can decide what to earn <strong>per booking</strong>.
            </p>
            <el-switch
              v-model="form.agentFinalAdjustment"
              active-text="Allow price adjustment on checkout" />
            <div class="m-t-4x m-b-4x">
              <p>
                <strong>Note:</strong> Any commission added to bookings will be paid to you the month after all check-outs.
              </p>
              <p>
                <strong class="has-text-danger">Important!</strong> We require payment for the total price (including any commission you add).
                If you are paying the booking with your agency credit card, EFT or Wallet, we suggest to leave all rates as Net.
              </p>
            </div>
            <el-button
              :disabled="!formChanged"
              class="button-secondary"
              style="min-width: 150px;"
              @click="updateAgentPricing">
              Save
            </el-button>
          </el-form>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MyAccountSidebar from './MyAccountSidebar'
import { divide, minus, plus, times } from 'number-precision'
import { retrieveAgent } from '@/service/auth'

export default {
  name: 'MyPricing',
  components: { MyAccountSidebar },
  data () {
    return {
      isFetching: false,
      form: {
        markup: undefined,
        discount: undefined,
        agentFinalAdjustment: false
      },
      oldFormData: undefined
    }
  },
  computed: {
    currency () {
      return retrieveAgent('defaultCurrency')
    },
    examplePrice () {
      return this.currency === 'ZAR' ? 1000.00 : 100.00
    },
    exampleMarkup () {
      if (this.form.markup) {
        return times(this.examplePrice, plus(1, divide(this.form.markup, 100)))
      } else {
        return this.examplePrice
      }
    },
    exampleDiscount () {
      if (this.form.discount) {
        return times(this.exampleMarkup, minus(1, divide(this.form.discount, 100)))
      } else {
        return this.exampleMarkup
      }
    },
    formChanged () {
      return JSON.stringify(this.form) !== this.oldFormData
    }
  },
  watch: {
    '$route' (nV) {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.isFetching = true
      this.oldFormData = JSON.stringify(this.form)
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      this.$store.dispatch('app/getAgentPricing').then(
        () => {
          this.initForm(this.$store.state.app)
          this.isFetching = false
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
        }
      ).catch((err) => {
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
        this.isFetching = false
      })
    },
    updateAgentPricing () {
      const agentInitialAdjustmentAmountConfig = JSON.parse(JSON.stringify(this.$store.state.app.pricing.agentInitialAdjustmentAmountConfig))
      // agentInitialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct = this.form.discount ? 0 - this.form.discount / 100 : 0
      agentInitialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct = 0
      const payload = {
        agentMinMarkupInPct: this.form.markup ? this.form.markup / 100 : undefined,
        agentInitialAdjustmentAmountConfig,
        agentFinalAdjustment: this.form.agentFinalAdjustment
      }
      this.$store.commit('SHOW_FULLSCREEN_LOADER', 'saving')
      this.$store.dispatch('app/updateAgentPricing', payload).then(
        ({ outlets, meta }) => {
          this.$store.commit('meta/SET_META_ACTION', meta)
          this.$store.commit('app/SET_APP', { pricing: outlets.details })
          this.initForm({ pricing: outlets.details })
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
        }
      ).catch((err) => {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        this.$store.commit('meta/SET_META_ACTION', { meta: err.meta })
        this.$store.commit('SET_GLOBAL_MODAL_ERROR', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    initForm (app) {
      if (app.pricing) {
        this.form.markup = app.pricing.agentMinMarkupInPct ? app.pricing.agentMinMarkupInPct * 100 : app.pricing.agentMinMarkupInPct
        this.form.agentFinalAdjustment = app.pricing.agentFinalAdjustment || false
        this.form.discount = app.pricing.agentInitialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct ? Math.abs(app.pricing.agentInitialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct * 100).toFixed(0) : app.pricing.agentInitialAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.adjustmentPct
        this.oldFormData = JSON.stringify(this.form)
      }
    }
  },
  metaInfo () {
    return {
      title: 'Pricing'
    }
  }
}
</script>

<style lang="scss" scoped>
  .ex-title {
    width: 12.5em;
  }
</style>
