<template>
  <el-form
    ref="searchBookingsForm"
    :model="localForm"
    :rules="rules"
    class="columns is-multiline search-bookings-form"
    label-position="top">
    <div class="column is-12">
      <h2 class="is-size-3 has-text-weight-bold has-text-white">
        {{ $t('views.MyBookings.Manage Bookings') }}
      </h2>
    </div>
    <div class="column is-5">
      <div class="columns">
        <el-form-item
          :label="$t('views.MyBookings.form.dateRange.label')"
          class="column is-4 hide-border-radius-right"
          prop="dateRangeType">
          <el-select
            v-model="localForm.dateRangeType"
            :placeholder="$t('views.MyBookings.form.dateRange.placeholder')"
            @change="handleSearch">
            <el-option
              v-for="item in dateRageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item
          class="column is-8 hide-border-radius-left"
          label=" "
          prop="dateRange">
          <DatePicker
            v-model="localForm.dateRange"
            :hovering-tooltip="false"
            :min-nights="0"
            :no-min-date="true"
            :show-year="true"
            :display-clear-button="true"
            @input="handleSearch" />
        </el-form-item>
      </div>
    </div>
    <el-form-item
      class="column is-2"
      prop="orderStatuses"
      :label="$t('views.MyBookings.form.status.placeholder')">
      <el-select
        v-model="localForm.orderStatuses"
        :placeholder="$t('views.MyBookings.form.status.placeholder')"
        multiple
        collapse-tags
        @change="delaySearch">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item
      class="column is-2"
      prop="agentEarningStatus"
      label="Commission status">
      <el-select
        v-model="localForm.agentEarningStatus"
        placeholder="status"
        multiple
        collapse-tags
        @change="delaySearch">
        <el-option
          v-for="item in agentEarningStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value" />
      </el-select>
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="orderReference"
      :label="$t('views.MyBookings.form.orderReference.label')">
      <el-input
        v-model="localForm.orderReference"
        :placeholder="$t('views.MyBookings.form.orderReference.placeholder')"
        @input="delaySearch" />
      <template #error="{ error }">
        <div class="el-form-item__error">
          <span style="background-color: #FFE8E8">{{ error }}</span>
        </div>
      </template>
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="hotelName"
      :label="$t('views.MyBookings.form.hotelName.label')">
      <el-input
        v-model="localForm.propertyName"
        :placeholder="$t('views.MyBookings.form.hotelName.placeholder')"
        @input="delaySearch" />
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="guestName"
      :label="$t('views.MyBookings.form.guestName.label')">
      <el-input
        v-model="localForm.orderPerson"
        :placeholder="$t('views.MyBookings.form.guestName.placeholder')"
        @input="delaySearch" />
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="users"
      :label="$t('views.MyBookings.form.agentName.placeholder')">
      <el-select
        v-model="localForm.users"
        :placeholder="$t('views.MyBookings.form.agentName.placeholder')"
        filterable
        multiple
        collapse-tags
        @change="delaySearch">
        <el-option
          v-for="item in agentOptions"
          :key="item.uid"
          :label="item.displayName"
          :value="item.uid" />
      </el-select>
    </el-form-item>
    <el-form-item
      class="column is-3"
      prop="agentReference"
      :label="$t('views.MyBookings.form.agentReference.label')">
      <el-input
        v-model="localForm.agentReference"
        :placeholder="$t('views.MyBookings.form.agentReference.placeholder')"
        @input="delaySearch" />
    </el-form-item>

    <div class="column is-12 has-text-right">
      <div style="height: 19px;" />
      <el-button
        v-if="hasSearchParams"
        class="button-white-outline"
        @click="clear">
        {{ $t('components.FiltersPriceRange.clear') }}
      </el-button>
      <!--      <el-button-->
      <!--        class="button-secondary button-155"-->
      <!--        @click="handleSearch">-->
      <!--        {{ $t('views.share.search') }}-->
      <!--      </el-button>-->
    </div>
  </el-form>
</template>

<script>
import DatePicker from '@/components/DatePicker/index'
import debounce from 'lodash/debounce'
import moment from 'moment'

export default {
  name: 'SearchBookingsForm',
  components: { DatePicker },
  data () {
    return {
      localForm: {
        dateRangeType: 'checkin-date',
        dateRange: {
          checkInDate: '',
          checkOutDate: ''
        },
        orderReference: '',
        propertyName: '',
        orderPerson: '',
        users: [],
        orderStatuses: [],
        agentEarningStatus: [],
        agentReference: ''
      },
      agentOptions: [],
      dateRageOptions: [
        {
          value: 'checkin-date',
          label: this.$t('views.CheckoutFormSummary.check-in-date')
        },
        {
          value: 'checkout-date',
          label: this.$t('views.CheckoutFormSummary.check-out-date')
        },
        {
          value: 'booking-date',
          label: this.$t('views.MyBookings.booking-date')
        }
      ],
      agentEarningStatusOptions: [
        {
          value: 'PAID',
          label: 'Paid'
        },
        {
          value: 'UNPAID',
          label: 'Unpaid'
        }
      ],
      statusOptions: [
        // {
        //   value: undefined,
        //   label: this.$t('views.MyBookings.status.all')
        // },
        {
          value: 'CONFIRMED',
          label: this.$t('views.MyBookings.status.confirmed')
        },
        {
          value: 'ON_HOLD',
          label: this.$t('views.MyBookings.status.on-hold')
        },
        {
          value: 'PROCESSING',
          label: this.$t('views.MyBookings.status.processing')
        },
        {
          value: 'PENDING',
          label: this.$t('views.MyBookings.status.pending')
        },
        // NOTE: AP-699, to remove AWAITING_CONFIRMATION from the dropdown option
        // {
        //   value: 'AWAITING_CONFIRMATION',
        //   label: this.$t('views.MyBookings.status.awaiting-confirmation')
        // },
        {
          value: 'PAYMENT_PENDING',
          label: this.$t('views.MyBookings.status.payment-pending')
        },
        {
          value: 'CANCELLED',
          label: this.$t('views.MyBookings.status.cancelled')
        },
        {
          value: 'FAILURE',
          label: this.$t('views.MyBookings.status.booking-failure')
        },
        {
          value: 'REFUND_IN_PROGRESS',
          label: this.$t('views.MyBookings.status.refund-in-progress')
        },
        {
          value: 'REFUND_FAILURE',
          label: this.$t('views.MyBookings.status.refund-failure')
        }
      ],
      rules: {
        guestName: [
          { type: 'string', min: 2, message: this.$t('views.MyBookings.form.guestName.invalid'), trigger: [ 'change', 'input' ] }
        ],
        dateRange: [
          // { type: 'string', pattern: /^\d{4}-[0,1]{1}\d{1}-[0,1,2,3]{1}\d{1}$/, message: 'Invalid date format (YYYY-MM-DD)', trigger: [ 'change', 'input' ] }
        ],
        orderReference: [
          { type: 'string', min: 4, message: this.$t('views.MyBookings.form.orderReference.invalid'), trigger: [ 'change', 'input' ] }
        ]
      }
    }
  },
  computed: {
    hasSearchParams () {
      return this.localForm.dateRange.checkOutDate || this.localForm.dateRange.checkInDate || this.localForm.orderReference || this.localForm.propertyName || this.localForm.orderPerson || this.localForm.users.length || this.localForm.orderStatuses.length || this.localForm.agentReference || this.localForm.agentEarningStatus.length
    }
  },
  created () {
    this.$store.dispatch('user/fetchUsers').then(
      ({ outlets, meta }) => {
        this.agentOptions = outlets.items
        this.$store.commit('order/SET_AGENT_OPTIONS', outlets.items)
      })
    const query = this.$route.query
    Object.keys((query)).forEach((key) => {
      if (['checkInDateRange', 'bookingDateRange', 'checkoutDateRange'].indexOf(key) > -1) {
        const range = query[key].split('|')
        this.localForm.dateRange.checkInDate = range[0]
        this.localForm.dateRange.checkOutDate = range[1]
      } else if (key === 'orderStatuses') {
        this.localForm.orderStatuses = query.orderStatuses.split(',')
      } else if (key === 'dateRangeType') {
        this.localForm.dateRangeType = query.dateRangeType
      } else if (key === 'users') {
        this.localForm.users = query.users.split(',')
      } else if (key === 'propertyName') {
        this.localForm.propertyName = query.propertyName
      } else if (key === 'orderReference') {
        this.localForm.orderReference = query.orderReference
      } else if (key === 'agentReference') {
        this.localFor.agentReference = query.agentReference
      } else if (key === 'orderPerson') {
        this.localForm.orderPerson = query.orderPerson
      } else if (key === 'dateRangeType') {
        this.localForm.dateRangeType = query.dateRangeType
      }
    })
  },
  methods: {
    clear () {
      this.localForm.dateRangeType = 'checkin-date'
      this.localForm.dateRange = {
        checkInDate: '',
        checkOutDate: ''
      }
      this.localForm.orderReference = ''
      this.localForm.propertyName = ''
      this.localForm.users = []
      this.localForm.orderPerson = ''
      this.localForm.orderStatuses = []
      this.localForm.agentEarningStatus = []
      this.localForm.agentReference = ''

      this.$router.replace({
        name: 'my-bookings'
      })
      this.$emit('submitted')
    },
    handleSearch () {
      this.$refs.searchBookingsForm.validate().then((valid) => {
        if (valid) {
          if (this.localForm.orderStatuses.includes(undefined)) {
            this.localForm.orderStatuses = [undefined] // fix bug: select two status `all , confirmed` server side response error
          }
          if (this.localForm.agentEarningStatus.includes(undefined)) {
            this.localForm.agentEarningStatus = [undefined] // fix bug: select two status `all , confirmed` server side response error
          }
          const newQuery = {}
          if (this.localForm.dateRange.checkInDate !== '') {
            const start = moment(this.localForm.dateRange.checkInDate).format('YYYY-MM-DD')
            const end = moment(this.localForm.dateRange.checkOutDate).format('YYYY-MM-DD')
            if (this.localForm.dateRangeType === 'checkin-date') {
              newQuery.checkInDateRange = `${start}|${end}`
            } else if (this.localForm.dateRangeType === 'booking-date') {
              newQuery.bookingDateRange = `${start}|${end}`
            } else if (this.localForm.dateRangeType === 'checkout-date') {
              newQuery.checkoutDateRange = `${start}|${end}`
            }
          }
          if (this.localForm.orderStatuses.length) {
            newQuery.orderStatuses = this.localForm.orderStatuses.join(',')
          }
          if (this.localForm.users.length) {
            newQuery.users = this.localForm.users.join(',')
          }
          if (this.localForm.agentEarningStatus.length) {
            newQuery.agentEarningStatus = this.localForm.agentEarningStatus.join(',')
          }
          if (this.localForm.propertyName) {
            newQuery.propertyName = this.localForm.propertyName
          }
          if (this.localForm.orderPerson) {
            newQuery.orderPerson = this.localForm.orderPerson
          }
          if (this.localForm.agentReference) {
            newQuery.agentReference = this.localForm.agentReference
          }
          if (this.localForm.orderReference) {
            newQuery.orderReference = this.localForm.orderReference
          }
          if (this.localForm.dateRangeType) {
            newQuery.dateRangeType = this.localForm.dateRangeType
          }
          this.$router.replace({
            name: 'my-bookings',
            query: { ...newQuery, page: 1 }
          })
          this.$emit('submitted')
        }
      })
    },
    delaySearch: debounce(function () {
      this.handleSearch()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
  .search-bookings-form {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: -10px;
    margin-bottom: -10px;

    .columns {
      margin-left: -8px;
      margin-right: -8px;
      margin-top: -10px;

      &:last-child {
        margin-bottom: -10px;
      }
    }

    .column {
      padding: 10px 8px;
    }

    .el-select {
      width: 100%;
    }
  }
</style>
