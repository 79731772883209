<template>
  <div class="dashboard-page">
    <div class="hero-my-bookings has-background-primary">
      <div class="container">
        <SearchBookingsForm
          ref="searchBookingsForm"
          @submitted="fetchHistory" />
      </div>
    </div>
    <div
      id="order-list"
      class="section">
      <div class="container">
        <MyBookingsHistory
          :items="rows"
          :total-items="summary && rows.length ? summary.total : 0"
          :filters="filters"
          :page-size="pageSize"
          :page="page"
          @exported="handleExport"
          @historyPageChanged="handlePageChanged" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

import SearchBookingsForm from '@/views/customer/SearchBookingsForm'
import MyBookingsHistory from '@/views/customer/MyBookingsHistory'
import { download } from '@/plugins/util'

const asyncData = async ({ params }) => {
  try {
    // to indicate superadmin
    params.superAdmin = false
    await store.dispatch('order/retrieveOrderList', params)
  } catch (err) {
    if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
    store.commit('SET_GLOBAL_MODAL', {
      show: true,
      content: err.message || (err.error && err.error.message) || ''
    })
  }
}

export default {
  name: 'MyBookings',
  components: {
    MyBookingsHistory,
    SearchBookingsForm
  },
  data () {
    return {
      query: this.$route.query,
      pageSize: 10,
      page: 1,
      orderFilters: {},
      defaultOrderStatus: [
        'CONFIRMED',
        'CANCELLED',
        'ON_HOLD',
        'REFUND_FAILURE',
        'FAILURE',
        'REFUND_IN_PROGRESS'
      ]
    }
  },
  computed: {
    ...mapState('order', {
      summary: state => state.orders.summary,
      rows: state => state.orders.rows,
      cols: state => state.orders.cols,
      sort: state => state.orders.cols,
      isFetching: state => state.isFetching,
      isContinuePulling: state => state.orderDetails.isContinuePulling,
      filters: state => state.filters
    })
  },
  created () {
    this.page = Number(this.$route.query.page) || 1
    this.fetchHistory()
  },
  methods: {
    async fetchHistory () {
      this.page = Number(this.$route.query.page) || 1
      this.pageSize = Number(this.$route.query.limit) || 10
      this.$store.commit('SHOW_FULLSCREEN_LOADER')
      await asyncData({
        params: {
          ...this.$route.query,
          ...(this.$route.query.orderStatuses ? { orderStatuses: this.$route.query.orderStatuses } : { orderStatuses: this.defaultOrderStatus.join(',') }),
          limit: this.pageSize,
          page: this.page
        }
      })
      this.$store.commit('HIDE_FULLSCREEN_LOADER')
    },
    sanitizeQuery (query) {
      const dateRange = query.dateRange
      const sanitized = {
        dateRangeType: query.dateRangeType,
        ...(dateRange !== '|' && dateRange !== '' ? { dateRange } : undefined),
        ...(query.orderPerson ? { orderPerson: query.orderPerson } : undefined),
        ...(query.orderReference ? { orderReference: query.orderReference } : undefined),
        ...(query.agentReference ? { agentReference: query.agentReference } : undefined),
        ...(query.propertyName ? { propertyName: query.propertyName } : undefined),
        ...(Array.isArray(query.users) && query.users.length > 0
          ? {
            users: query.users.join(',')
          } : undefined),
        ...(Array.isArray(query.orderStatuses) && query.orderStatuses.length > 0
          ? {
            orderStatuses: query.orderStatuses.join(',')
          } : { orderStatuses: this.defaultOrderStatus.join(',') }),
        ...(Array.isArray(query.agentEarningStatus) && query.agentEarningStatus.length > 0
          ? {
            agentEarningStatus: query.agentEarningStatus.join(',')
          } : undefined),
        page: query.page ? query.page : 1
      }
      return sanitized
    },
    handlePageChanged ({ page, limit }) {
      if (limit !== this.pageSize) {
        page = 1
      }
      const newQuery = { ...this.$route.query, page, limit }
      this.page = page
      this.$router.replace({
        name: 'my-bookings',
        query: newQuery
      })
      this.fetchHistory()
    },
    handleExport () {
      store.dispatch('order/exportXLS', {
        ...this.$route.query,
        cols: 'propertyName,stayPeriods,requestAt,guestName,freeCancellationBefore,createdBy,agentActualChargeAmount,orderStatus,agentReferenceCode,paymentMethod',
        superAdmin: false
      }).then(({
        data,
        headers
      }) => {
        download(data, headers)
      }).catch((err) => {
        if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    }
  },
  metaInfo () {
    return {
      title: 'Manage Bookings'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
